import { pushTracking } from '@src/utils';
import { PARAM_VALUE } from '@constants';
import { Params, Event } from '@src/type/googleAnalytics';
import {
  FormErrorEvent,
  FormStartEvent,
  FormSubmitEvent,
  RegistrationFlowEvent,
} from '../types/DataTracking';
import { logEvent } from '../../../../src/utils/amplitude';

const formStartTracking = ({
  formName,
  stepLabel,
  planName,
  planTier,
  planBillingFreq,
}: FormStartEvent) => {
  pushTracking({
    event: Event.FORM_START,
    [Params.FORM_NAME]: formName,
    [Params.STEP_LABEL]: stepLabel,
    [Params.PLAN_NAME]: planName,
    [Params.PLAN_TIER]: planTier,
    [Params.PLAN_BILLING_FREQ]: planBillingFreq,
  });
};

const formSubmitTracking = ({
  formName,
  stepLabel,
  planName,
  planTier,
  planBillingFreq,
}: FormSubmitEvent) => {
  pushTracking({
    event: Event.FORM_SUBMIT,
    [Params.FORM_NAME]: formName,
    [Params.STEP_LABEL]: stepLabel,
    [Params.PLAN_NAME]: planName,
    [Params.PLAN_TIER]: planTier,
    [Params.PLAN_BILLING_FREQ]: planBillingFreq,
  });
};

const formErrorTracking = ({
  formName,
  stepLabel,
  formErrorName,
  planName,
  planTier,
  planBillingFreq,
}: FormErrorEvent) => {
  pushTracking({
    event: Event.FORM_ERROR,
    [Params.FORM_NAME]: formName,
    [Params.STEP_LABEL]: stepLabel,
    [Params.FORM_ERROR_NAME]: formErrorName,
    [Params.PLAN_NAME]: planName,
    [Params.PLAN_TIER]: planTier,
    [Params.PLAN_BILLING_FREQ]: planBillingFreq,
  });
};

const registrationFlowTracking = ({
  formName,
  stepLabel,
  planName,
  planTier,
  planBillingFreq,
  currency,
  planPrice,
  customerEmail,
  customerFirstName,
  customerLastName,
  planId,
  eventCallBack,
}: RegistrationFlowEvent) => {
  logEvent('registration_flow', {
    form_name: formName,
    step_label: stepLabel,
  });
  pushTracking({
    event: Event.REGISTRATION_FLOW,
    [Params.FORM_NAME]: formName,
    [Params.STEP_LABEL]: stepLabel,
    [Params.PLAN_NAME]: planName,
    [Params.PLAN_TIER]: planTier,
    [Params.PLAN_BILLING_FREQ]: planBillingFreq,
    [Params.CURRENCY]: currency,
    [Params.PLAN_PRICE]: planPrice,
    [Params.CUSTOMER_EMAIL]: customerEmail,
    [Params.CUSTOMER_FIRST_NAME]: customerFirstName,
    [Params.CUSTOMER_LAST_NAME]: customerLastName,
    [Params.PLAN_ID]: planId,
    eventCallback: eventCallBack && eventCallBack(),
  });
};

const customerDetailsUpdateTracking = (vimeoId?: string | null) => {
  pushTracking({
    event: Event.CUSTOMER_DETAILS_UPDATE,
    [Params.CUSTOMER_LOGGED_IN_STATUS]: PARAM_VALUE.FALSE,
    [Params.VIMEO_ID]: vimeoId,
  });
};

export {
  formStartTracking,
  formSubmitTracking,
  formErrorTracking,
  registrationFlowTracking,
  customerDetailsUpdateTracking,
};
