/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { isToday } from 'date-fns';
import { Link } from 'gatsby';
import { StatsCard, VideoCard } from '@lesmills-international/components';
import { useWindowSize } from '@hooks';
import { useWorkoutByNameQuery } from '@backend';
import { getSlideSpace, getSlidesPerView, getWorkoutForVideoCard } from '@services';
import { UserContext, userContext } from '@context';
import { Carousel } from '@components/atoms';
import { ReactComponent as RecoveryIcon } from '@assets/icons/recovery.svg';
import { ScheduledWorkout } from '../../../hooks/useMyScheduledWorkouts';

import {
  MyScheduledWorkoutsWrapper,
  TitleWrapper,
  Title,
  Description,
  ContentContainer,
  EmptyScheduledWorkoutsWrapper,
  CarouselWrapper,
} from './style';

interface Props {
  content: Queries.PrismicNewWebHomepageDataBodyMyScheduledWorkouts;
  myScheduledWorkouts?: ScheduledWorkout[];
}

interface WorkOutComponentProps {
  index: number;
  videoId: string;
  userInfo: UserContext;
  isDone: boolean;
  planName: string;
  workoutScheduleTitle: string | null;
}

const WorkOutComponent: FC<WorkOutComponentProps> = ({
  index,
  videoId,
  userInfo,
  isDone,
  planName,
  workoutScheduleTitle,
}) => {
  const { data: workoutData, loading } = useWorkoutByNameQuery({
    variables: {
      product: 'LMOD',
      workoutName: videoId,
    },
  });

  if (loading) return null;

  if (workoutData?.workoutByName?.items?.length === 0) return null;

  const item = workoutData?.workoutByName?.items?.[0];

  return (
    <VideoCard
      {...getWorkoutForVideoCard({
        item,
        userInfo,
        hideEquipment: true,
        contentListIndex: index + 1,
        contentListTitle: workoutScheduleTitle,
      })}
      isDone={isDone}
      label={planName}
    />
  );
};

const MyScheduledWorkouts: FC<Props> = ({ content, myScheduledWorkouts }) => {
  const {
    title,
    download_app_description,
    choose_a_plan_cta_label,
    plans_page,
    empty_plan_title,
    recovery_day_title,
  } = content?.primary || {};

  const [todayScheduledWorkouts, setTodayScheduledWorkouts] = useState<ScheduledWorkout[]>([]);
  const [videoCardItems, setVideoCardItems] = useState<JSX.Element[]>([]);
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState<number>(4);
  const [carouselSlideSpaceBetween, setCarouselSlideSpaceBetween] = useState<number>(8);

  const windowInnerWidth = useWindowSize();
  const userInfo = useContext(userContext);
  const carouselRef = useRef<HTMLDivElement>(null);
  const WORKOUT_COMPLETED_STATUS = 'COMPLETED';
  useEffect(() => {
    if (myScheduledWorkouts && myScheduledWorkouts.length > 0) {
      const todayWorkouts = myScheduledWorkouts.filter((workout: ScheduledWorkout) =>
        isToday(new Date(workout.scheduledDate))
      );
      setTodayScheduledWorkouts(todayWorkouts);
    }
  }, [myScheduledWorkouts]);

  useEffect(() => {
    if (carouselRef && carouselRef.current && todayScheduledWorkouts.length) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = getSlidesPerView(carouselWrapperWidth);

      const sortedWorkouts = [...todayScheduledWorkouts].sort((a, b) => {
        if (a.status === WORKOUT_COMPLETED_STATUS && b.status !== WORKOUT_COMPLETED_STATUS) {
          return 1;
        }
        if (a.status !== WORKOUT_COMPLETED_STATUS && b.status === WORKOUT_COMPLETED_STATUS) {
          return -1;
        }
        return 0;
      });

      const workoutVideoItems = sortedWorkouts
        ? sortedWorkouts?.map((item: ScheduledWorkout, index: number) => (
            <WorkOutComponent
              index={index}
              videoId={item?.workout?.id?.replace(/^.*\|/, '') || ''}
              userInfo={userInfo}
              isDone={item?.status === WORKOUT_COMPLETED_STATUS}
              planName={item?.plan?.name || 'MY SCHEDULE'}
              workoutScheduleTitle={title}
            />
          ))
        : [];
      if (workoutVideoItems?.length < slidesPerView) {
        for (let i = 0; i < slidesPerView - workoutVideoItems.length; i += 1) {
          workoutVideoItems.push(<div key={`placeholder-${i}`} />);
        }
      }

      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlideSpaceBetween(getSlideSpace(carouselWrapperWidth));
      setVideoCardItems(workoutVideoItems.filter(Boolean));
    }
  }, [todayScheduledWorkouts, windowInnerWidth, userInfo, title]);

  return (
    <MyScheduledWorkoutsWrapper>
      <TitleWrapper>
        <Title>{title}</Title>
        {myScheduledWorkouts?.length === 0 && <Description>{download_app_description}</Description>}
      </TitleWrapper>
      <ContentContainer>
        {myScheduledWorkouts?.length === 0 && (
          <EmptyScheduledWorkoutsWrapper>
            <Link to={plans_page?.url} className="choose-plan-button">
              {choose_a_plan_cta_label}
            </Link>
          </EmptyScheduledWorkoutsWrapper>
        )}
        {myScheduledWorkouts?.length !== 0 && todayScheduledWorkouts.length === 0 && (
          <StatsCard
            label={empty_plan_title}
            title={recovery_day_title}
            thumbnailIcon={<RecoveryIcon />}
            hasBackground={windowInnerWidth >= 600}
          />
        )}
        {todayScheduledWorkouts.length > 0 && (
          <CarouselWrapper ref={carouselRef}>
            <Carousel
              items={videoCardItems}
              slidesPerView={carouselSlidesPerView}
              slidesPerGroup={carouselSlidesPerView}
              spaceBetween={carouselSlideSpaceBetween}
            />
          </CarouselWrapper>
        )}
      </ContentContainer>
    </MyScheduledWorkoutsWrapper>
  );
};

export default MyScheduledWorkouts;
