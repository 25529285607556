import React from 'react';
import { Button } from '@lesmills-international/components';
import { navigate } from 'gatsby';
import { Wrapper, Title, Message, ButtonWrapper } from './styles';

type DoubleOptInProps = {
  title: string | null;
  message: string | null;
  button: {
    text: Queries.Maybe<string>;
    link?: Queries.Maybe<string>;
  };
};

const DoubleOptIn = ({ title, message, button }: DoubleOptInProps) => (
  <Wrapper>
    <Title>{title}</Title>
    <Message>{message}</Message>
    <ButtonWrapper>
      <Button
        ctaButton
        onClick={() => {
          navigate(`${button.link}`);
        }}
      >
        {button.text}
      </Button>
    </ButtonWrapper>
  </Wrapper>
);

export default DoubleOptIn;
