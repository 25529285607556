import { useState, useEffect } from 'react';
import { startOfDay } from 'date-fns';
import { createEngagementClient } from '../../../../src/services/client';
import GET_MY_SCHEDULED_WORKOUTS from '../engagementGql/myWorkoutPlanner';
import { addErrorInDatadogRum, getFiveMonthsLaterDate } from '../utils/utilities';

export interface ScheduledWorkout {
  id: string;
  scheduledDate: string;
  status: string | null;
  plan: {
    id: string;
    name: string;
  };
  workout: {
    id: string;
    _typename: string;
  };
}

interface UseMyScheduledWorkoutsReturn {
  myScheduledWorkouts: ScheduledWorkout[];
  loading: boolean;
}

const useMyScheduledWorkouts = (): UseMyScheduledWorkoutsReturn => {
  const [myScheduledWorkouts, setMyScheduledWorkouts] = useState<ScheduledWorkout[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchScheduledWorkouts = async () => {
      try {
        setLoading(true);
        const now = new Date();
        const todayStart = startOfDay(now);
        const todayTimestamp = todayStart.toISOString();

        const data = await createEngagementClient.query({
          query: GET_MY_SCHEDULED_WORKOUTS,
          fetchPolicy: 'network-only',
          variables: {
            from: todayTimestamp,
            to: getFiveMonthsLaterDate(),
          },
        });

        if (data?.data?.myWorkoutPlanner) {
          setMyScheduledWorkouts(data.data.myWorkoutPlanner.plannedWorkouts);
        }
      } catch (error) {
        addErrorInDatadogRum(error);
      } finally {
        setLoading(false);
      }
    };

    fetchScheduledWorkouts();
  }, []);

  return { myScheduledWorkouts, loading };
};

export default useMyScheduledWorkouts;
