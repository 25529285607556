import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import UpdateUserV2 from '../../graphql/updateUserV2';

interface UpdateUserArgs {
  input: {
    givenName: string | null;
    familyName: string | null;
    email: string | null;
  };
}

const useUpdateUserV2 = (
  options?: MutationHookOptions<boolean, UpdateUserArgs>
): MutationTuple<boolean, UpdateUserArgs> =>
  useMutation<boolean, UpdateUserArgs>(UpdateUserV2, options);

export default useUpdateUserV2;
