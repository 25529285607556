/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useState } from 'react';
import { Alert } from '@lesmills-international/components';
import { navigate } from 'gatsby';

import {
  ChangePasswordErrorMessage,
  NotificationSeverity,
  PersonalProfileErrorMessages,
  PersonalProfileSuccessMessage,
} from './types';
import ChangePassword from './ChangePassword';
import { FormDivider } from './styles';
import SubscriptionManagementLayout from '../common/subscriptionManagementLayout';
import UpdatePersonalProfile from './PersonalProfile';
import {
  customerDetailsUpdateTracking,
  formErrorTracking,
  formSubmitTracking,
} from '../../utils/dataTracking';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import { FormErrorName, FormName } from '../../types/DataTracking';
import { getPlanPeriod } from '../../../../../src/utils/utilities';
import useFormStartPushTracking from '../../hooks/useFormStartPushTracking';
import ROUTES from '../../../../../src/constants/routes';

interface Props {
  pageData: Queries.PrismicAccountDetailsPageData;
}

interface NotificationType {
  message?: string | null;
  severity: NotificationSeverity;
}

const AccountDetails = ({ pageData }: Props) => {
  const [notification, setNotification] = useState<NotificationType | null>(null);
  const { vimeoUserId, lmodSubscription, tier } = useContext(userContext);

  const { product_price_point, name: planName } = lmodSubscription?.product || {};

  const {
    edit_personal_details_title,
    first_name_field_label,
    last_name_field_label,
    email_field_label,
    edit_personal_details_submit_button_text,
    change_password_title,
    current_password_field_label,
    new_password_field_label,
    change_password_submit_button_text,
    update_personal_information_success,
    update_email_success,
    update_name_success,
    change_password_success,
    existing_email_error,
    email_required,
    name_required,
    password_required,
    current_password_required,
    email_invalid,
    password_invalid,
    password_space_error,
    first_name_required,
    last_name_required,
    invalid_password_exception,
    not_authorized_exception,
    personal_profile_server_error,
    change_password_server_error,
    limit_exceeded_exception,
  } = pageData;

  const changePasswordErrorMessage: ChangePasswordErrorMessage = {
    password_required,
    current_password_required,
    password_invalid,
    password_space_error,
    invalid_password_exception,
    not_authorized_exception,
    change_password_server_error,
    limit_exceeded_exception,
  };

  const editProfileErrorMessages: PersonalProfileErrorMessages = {
    first_name_required,
    last_name_required,
    name_required,
    existing_email_error,
    email_required,
    email_invalid,
    personal_profile_server_error,
  };

  const editProfileSuccessMessage: PersonalProfileSuccessMessage = {
    update_personal_information_success,
    update_email_success,
    update_name_success,
  };

  const defaultTrackingData = {
    formName: FormName.EDIT_PERSONAL_DETAILS_FORM,
    planName,
    planTier: tier,
    planBillingFreq:
      product_price_point &&
      getPlanPeriod(product_price_point?.interval, product_price_point?.interval_unit),
  };

  const handleFormSubmitTracking = () => {
    formSubmitTracking(defaultTrackingData);
    customerDetailsUpdateTracking(vimeoUserId);
  };

  const { handleFormStarted } = useFormStartPushTracking(defaultTrackingData);

  const handleFormTrackingError = (formErrorName: FormErrorName) => {
    formErrorTracking({
      ...defaultTrackingData,
      formErrorName,
    });
  };

  return (
    <SubscriptionManagementLayout
      title={edit_personal_details_title}
      size="narrow"
      backLink={{ handleClick: () => navigate(ROUTES().ACCOUNT) }}
      omitFooter
      metaData={{
        title: 'Account Details',
      }}
    >
      {notification && (
        <Alert severity={notification.severity} onClose={() => setNotification(null)}>
          {notification.message}
        </Alert>
      )}
      <UpdatePersonalProfile
        firstNameLabel={first_name_field_label}
        lastNameLabel={last_name_field_label}
        emailLabel={email_field_label}
        errorMessage={editProfileErrorMessages}
        successMessage={editProfileSuccessMessage}
        buttonText={edit_personal_details_submit_button_text}
        handleNotification={(message, severity) => setNotification({ message, severity })}
        successCallback={handleFormSubmitTracking}
        handleOnFormStart={handleFormStarted}
        handleFormTrackingError={handleFormTrackingError}
      />
      <FormDivider />
      <ChangePassword
        title={change_password_title}
        oldPasswordLabel={current_password_field_label}
        newPasswordLabel={new_password_field_label}
        buttonText={change_password_submit_button_text}
        errorMessage={changePasswordErrorMessage}
        successMessage={change_password_success}
        handleNotification={(message, severity) => setNotification({ message, severity })}
        successCallback={handleFormSubmitTracking}
        handleOnFormStart={handleFormStarted}
        handleFormTrackingError={handleFormTrackingError}
      />
    </SubscriptionManagementLayout>
  );
};

export default AccountDetails;
