import React, { FC, ReactNode } from 'react';
import { colors, BackButton } from '@lesmills-international/components';
import Helmet from 'react-helmet';

import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

// eslint-disable-next-line import/no-relative-packages
import Footer from '../../../../../gatsby-theme-engagement/src/components/molecules/footer';
// eslint-disable-next-line import/no-relative-packages
import Header from '../../../../../gatsby-theme-engagement/src/components/molecules/header';
import Metadata from '../../../../../gatsby-theme-engagement/src/components/molecules/metadata';

import { Wrapper, Title, SubTitle, Container, Size, LogoContainer } from './style';
import { useTrackUserAttribute } from '../../../../../gatsby-theme-engagement/src/hooks';
import { Theme } from '../../../../../../src/type';
import { breakpoints } from '../../../themes/breakpoints';

type BackLinkType = {
  handleClick?: () => void;
};

interface Props {
  children?: ReactNode;
  theme?: Theme;
  scripts?: Array<{ type?: string; src?: string }>;
  title?: string | null;
  subTitle?: string;
  backLink?: BackLinkType | null;
  size?: Size;
  hideHeader?: boolean;
  alignCenter?: boolean;
  icon?: ReactNode;
  omitFooter?: boolean;
  backgroundColor?: string;
  metaData?: {
    title?: string | null;
    description?: string | null;
  };
}

interface BackLinkProps {
  backLink?: BackLinkType | null;
}

const BackLinkComponent: FC<BackLinkProps> = ({ backLink }) => {
  if (!backLink) {
    return null;
  }

  return (
    <BackButton style={{ marginBottom: 20 }} onClick={backLink?.handleClick} label="back-button" />
  );
};

const SubscriptionManagementLayout: FC<Props> = ({
  children,
  scripts,
  title,
  subTitle,
  backLink,
  size,
  hideHeader,
  alignCenter,
  icon,
  theme,
  omitFooter,
  backgroundColor,
  metaData,
}) => {
  useTrackUserAttribute();

  const MUICreateTheme = createTheme({
    themeColor: theme,
    breakpoints: {
      values: {
        ...breakpoints,
      },
    },
  });

  return (
    <MUIThemeProvider theme={MUICreateTheme}>
      <ThemeProvider theme={{ colors, themeColor: theme }}>
        {metaData && <Metadata {...metaData} />}
        {scripts && <Helmet script={scripts} />}
        <Container hideHeader={hideHeader} backgroundColor={backgroundColor}>
          {!hideHeader && <Header />}
          <Wrapper size={size} alignCenter={alignCenter}>
            {icon && <LogoContainer>{icon}</LogoContainer>}
            {backLink && <BackLinkComponent backLink={backLink} />}
            {title && <Title marginBottom={subTitle ? 3.5 : undefined}>{title}</Title>}
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
            {children}
          </Wrapper>
          {!omitFooter && <Footer />}
        </Container>
      </ThemeProvider>
    </MUIThemeProvider>
  );
};

export default SubscriptionManagementLayout;
