/* eslint max-len: ["error", { "code": 120 }] */
import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import ErrorImage from '../assets/images/404.svg';

import Layout from '../components/common/layout';

import { getLocalStorage, deleteLocalStorage } from '../utils/utilities';
import { partnerSignupStatus } from '../constants/partnerStatus';
import PartnerError from '../components/partner/error/PartnerError';
import { ACTIVATION_CODE_FORMATTED, PARTNER_SIGNUP_STATUS } from '../constants/localStorageKeys';

const PartnerSignupErrorTemplate = ({ data, pageContext }) => {
  const { prismicSignUpLayout, prismicPartnerGenericErrorPage } = data;
  const { lang } = pageContext;
  const signupErrorStatus = getLocalStorage(PARTNER_SIGNUP_STATUS);

  const {
    description_message: descriptionMessage,
    generic_error_title: genericErrorTitle,
    contact_message: contactMessage,
    button_text: normalButtonText,
    cannot_activate_title: cannotActivateTitle,
    cannot_activate_description_message: cannotActivateDescriptionMessage,
    cannot_activate_button_text: cannotActivateButtonText,
  } = prismicPartnerGenericErrorPage.data;

  let title = genericErrorTitle;
  let buttonText = normalButtonText;
  let description = descriptionMessage;

  const isCantUpdateExistingSubscription =
    signupErrorStatus === partnerSignupStatus.CANNOT_ACTIVATE;

  if (isCantUpdateExistingSubscription) {
    title = cannotActivateTitle;
    buttonText = cannotActivateButtonText;
    description = [cannotActivateDescriptionMessage, contactMessage].join('\n\n');
  }

  const handleClick = () => {
    deleteLocalStorage(PARTNER_SIGNUP_STATUS);
    deleteLocalStorage(ACTIVATION_CODE_FORMATTED);
    window.location.href = process.env.GATSBY_SUPPORT_PAGES_HOME;
  };

  return (
    <Layout
      data={prismicSignUpLayout.data}
      contentWidth={380}
      currentStep={0}
      omitClose
      lang={lang}
      pathName="/partner/signup/error"
      mobileBottomMargin={120}
      title="Partner Signup Error"
    >
      <PartnerError
        image={ErrorImage}
        title={title}
        description={description}
        buttonText={buttonText}
        onButtonClick={handleClick}
      />
    </Layout>
  );
};

export default withPrismicPreview(PartnerSignupErrorTemplate);

export const query = graphql`
  query ($lang: String!) {
    prismicPartnerGenericErrorPage(lang: { eq: $lang }) {
      _previewable
      data {
        contact_message
        cannot_activate_description_message
        description_message
        button_text
        cannot_activate_button_text
        generic_error_title
        cannot_activate_title
      }
    }
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }
  }
`;
