import React, { FC, useEffect, useState } from 'react';
import { breakpoints } from '@themes';
import { Filter, AlbumCard, VideoCardSlim } from '@lesmills-international/components';
import { Grid } from '@mui/material';
import { useWindowSize } from '@hooks';
import { betweenBreakpoints } from '@services';
import { contentClickTracking, linkClickTracking } from '@src/utils';
import { Event } from '@src/type';
import { SlicePlaceholder, RichText } from '@components/atoms';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import {
  InterestFilterWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  ContentWrapper,
  FilterWrapper,
  ProgramCard,
  OffsetWrapper,
  FilterWrapToNextLine,
} from './style';
import {
  Early_Tenure_Home_PageBodyEarly_Tenure_Interest_Based_Collection,
  Early_Tenure_Home_PageBodyEarly_Tenure_Interest_Based_CollectionFields,
} from '../../../generated/prismicGraphql';
import {
  formatCategories,
  getActiveProgramCategories,
  formatFilterDataFromPrismicAndCollection,
  type Category,
  type WorkoutType,
  type FilterDataType,
  type FormattedFilterDataType,
} from './utils';

interface InterestFilterContent
  extends Early_Tenure_Home_PageBodyEarly_Tenure_Interest_Based_Collection {
  content: {
    primary: {
      title: string;
      subtitle?: string;
      subtitle_with_url?: {
        raw: string;
      };
      fitnessLevel: string;
      categoriesSelected: string[];
    };
    items: Early_Tenure_Home_PageBodyEarly_Tenure_Interest_Based_CollectionFields[];
  };
}

const ProgramSection: FC<{
  currentFilterData: FilterDataType;
  sectionTitle: string;
}> = ({ currentFilterData, sectionTitle }) => {
  const windowInnerWidth = useWindowSize();
  const isSmallScreen =
    windowInnerWidth < breakpoints.xs || betweenBreakpoints(breakpoints.xs, windowInnerWidth, 1200);
  return (
    <Grid item xs={12} lg={6} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <ProgramCard isSmallScreen={isSmallScreen}>
        <AlbumCard
          title={currentFilterData.program_name}
          description={currentFilterData.program_subtitle}
          backgroundImg={currentFilterData.program_background_image?.url}
          label={currentFilterData.program_label}
          flexibleHeight={!isSmallScreen}
          height={isSmallScreen ? '246px' : undefined}
          link={currentFilterData.program_link}
          onClick={() => {
            contentClickTracking({
              contentListTitle: sectionTitle,
              contentListIndex: 1,
              contentTitle: currentFilterData.program_name,
              contentType: 'program',
            });
          }}
        />
      </ProgramCard>
    </Grid>
  );
};

interface WorkoutSectionProps {
  workouts: WorkoutType[];
  sectionTitle: string;
}

const WorkoutSection: FC<WorkoutSectionProps> = ({ workouts, sectionTitle }) => (
  <Grid item xs={12} lg={6} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
    <Grid container spacing={0} sx={{ flexGrow: 1 }}>
      {workouts?.map((workout, index) => {
        const isFirst = index === 0;
        const isLast = index === (workouts?.length || 0) - 1;
        const totalElements = workouts?.length || 0;

        return (
          <Grid item xs={12} key={index}>
            <OffsetWrapper isFirst={isFirst} isLast={isLast} totalElements={totalElements}>
              <VideoCardSlim
                name={workout.name}
                duration={workout.duration}
                equipmentIcons={workout.equipmentIcons}
                backgroundImg={workout.backgroundImg}
                link={workout.link}
                onClick={() => {
                  contentClickTracking({
                    contentListTitle: sectionTitle,
                    contentListIndex: index + 2,
                    contentTitle: workout.name,
                    contentType: 'workout',
                  });
                }}
              />
            </OffsetWrapper>
          </Grid>
        );
      })}
    </Grid>
  </Grid>
);
const EarlyTenureInterestFilter: FC<InterestFilterContent> = ({ content }) => {
  const [categories, setCategories] = useState<Category[]>(() => {
    const selectedCategories = !content?.primary?.categoriesSelected?.length
      ? (content?.items
          ?.filter((item) => item.default === true) // if user has not selected categories, get categories with default true from prismic
          ?.map((item) => item.option) // Get the option value of the categories (Yoga, Strength, etc.)
          ?.filter(Boolean) as string[]) // Filter out any falsy values
      : content?.primary?.categoriesSelected?.filter((category) => {
          // If user has selected categories, filter out the categories that are not allowed based on the fitness level
          if (content?.primary?.fitnessLevel !== 'Fit and ready to push limits') {
            // LPE-3303: If the fitness level is not 'Fit and ready to push limits', filter out 'HIIT'
            return category !== 'HIIT';
          }
          return true;
        });

    return formatCategories(selectedCategories).map((category, index) => ({
      ...category,
      active: index === 0,
    }));
  });

  const [formattedFilterData, setFormattedFilterData] = useState<FormattedFilterDataType>({});
  const [selectedOption, setSelectedOption] = useState(() => categories[0]?.id || '');
  const [currentFilterData, setCurrentFilterData] = useState<FilterDataType>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        setLoading(true);
        const data = await formatFilterDataFromPrismicAndCollection(
          content.items,
          content?.primary?.categoriesSelected
        );
        setFormattedFilterData(data);
      } catch (error) {
        addErrorInDatadogRum(error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilterData();
  }, [content.items, content?.primary?.categoriesSelected]);

  useEffect(() => {
    setCurrentFilterData(formattedFilterData[selectedOption] || {});
  }, [selectedOption, formattedFilterData]);

  const handleChangeCategory = (_, id: string) => {
    const { categories: updatedCategories } = getActiveProgramCategories(categories, id);
    setCategories(updatedCategories);
    setSelectedOption(id);
  };

  return (
    <InterestFilterWrapper>
      <TitleWrapper>
        <Title>{content?.primary?.title}</Title>
        {content?.primary?.subtitle && (
          <Subtitle>
            <RichText
              text={{ richText: content?.primary?.subtitle_with_url?.raw }}
              onClick={() => {
                linkClickTracking({
                  eventName: Event.UPDATE_FITNESS_PROFILE,
                  link: 'Update Fitness Profile',
                  contentListTitle: content?.primary?.title,
                });
              }}
              // Have to do this so the component can render the tailwind CSS
              // eslint-disable-next-line react/style-prop-object
              style="text-lightishGrey underline"
            />
          </Subtitle>
        )}
      </TitleWrapper>
      <ContentWrapper>
        <FilterWrapper>
          <FilterWrapToNextLine>
            {categories.length <= 1 ? null : (
              <Filter items={categories} handleClickItem={handleChangeCategory} />
            )}
          </FilterWrapToNextLine>
          <Grid
            container
            spacing={1}
            alignItems="stretch"
            sx={{
              minHeight: '370px',
              height: '100%',
            }}
          >
            {loading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: '370px' }}
              >
                <SlicePlaceholder height="370px" width="80%" />
              </Grid>
            ) : (
              <>
                <ProgramSection
                  currentFilterData={currentFilterData}
                  sectionTitle={content?.primary?.title}
                />
                <WorkoutSection
                  workouts={currentFilterData.workouts}
                  sectionTitle={content?.primary?.title}
                />
              </>
            )}
          </Grid>
        </FilterWrapper>
      </ContentWrapper>
    </InterestFilterWrapper>
  );
};

export default EarlyTenureInterestFilter;
