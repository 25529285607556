import styled from 'styled-components';
import { breakpoint } from '../../themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkGrey};
  width: 100%;

  ${breakpoint('md')`
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const Title = styled.h2`
  font-size: 60px;
  margin-bottom: 60px;
  margin-top: 80px;
  font-family: 'Inter SemiBold';
  text-transform: uppercase;
  letter-spacing: -0.03em;

  ${breakpoint('md')`
    font-size: 40px;
    line-height: 70px;
    width: 100%;
    text-align:center;
  `}
`;

export const Message = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 22px;
  margin-right: auto;
  margin-left: auto;
`;

export const ButtonWrapper = styled.div`
  margin-top: 80px;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;

  ${breakpoint('md')`
    width: auto;
    padding: 0;
    & .MuiButton-root {
      margin: 10px 1px;
    }
  `}

  & button {
    width: 100%;
  }
`;
