import { useEffect, useState } from 'react';
import { useGetSurveyUserAnswerQuery } from '@backend';

const useSurveyAnswers = () => {
  const [isRefetching, setIsRefetching] = useState(false);

  const {
    loading,
    data: survey,
    refetch,
  } = useGetSurveyUserAnswerQuery({
    variables: { id: 'onboarding' },
  });

  const handleRefetch = async () => {
    setIsRefetching(true);
    try {
      await refetch();
    } finally {
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    handleRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: loading || isRefetching,
    mySurveyAnswers: survey?.survey?.myAnswers,
    refetch: handleRefetch,
  };
};

export default useSurveyAnswers;
