import React, { FC, ReactNode } from 'react';
// eslint-disable-next-line import/no-unresolved
import { GatsbyImage } from 'gatsby-plugin-image';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import COLORS from '../../../themes/colors';
import { isBrowser } from '../../../utils/utilities';

import Footer, { NavItem } from '../footer/Footer';
import Seo from '../seo';
import Stepper from '../stepper/Stepper';

import {
  CloseButton,
  ContentWrapper,
  LeftSection,
  Logo,
  RightSection,
  ScrollWrap,
  Wrapper,
} from './style';
import { useTrackUserAttribute } from '../../../../../gatsby-theme-engagement/src/hooks';

const MUICreateTheme = createTheme();

interface Props {
  data: {
    background_image: {
      gatsbyImageData: object;
      alt?: string;
    };
    logo_black: {
      gatsbyImageData: object;
      alt?: string;
    };
    body: { items: NavItem[] }[];
  };
  currentStep: number;
  children: ReactNode;
  omitClose: boolean;
  lang: string;
  pathName: string;
  scripts: string[];
  mobileBottomMargin?: number;
  rightSectionStyle?: object;
  leftSectionStyle?: object;
  title?: string;
  description?: string;
}

const Layout: FC<Props> = ({
  data = {},
  currentStep = 0,
  children,
  omitClose,
  lang = 'en-nz',
  pathName = '',
  scripts = [],
  mobileBottomMargin,
  rightSectionStyle,
  leftSectionStyle,
  title,
  description,
}) => {
  const backgroundImage = data?.background_image;

  const logoBlack = data?.logo_black;
  const navData = data?.body[0]?.items;

  const handleClosePage = () => {
    if (isBrowser) {
      window.location.href = process.env.GATSBY_LANDING_PAGES_HOME;
    }
  };

  useTrackUserAttribute(true);

  const stepNumber = 3;

  return (
    <MUIThemeProvider theme={MUICreateTheme}>
      <ThemeProvider theme={{ colors: COLORS }}>
        <Wrapper id="layout-wrapper">
          <Seo
            lang={lang}
            pathname={pathName}
            scripts={scripts}
            title={title}
            description={description}
          />
          <LeftSection id="layout-leftsection-wrapper" style={leftSectionStyle}>
            <GatsbyImage
              id="layout-background-image"
              className="layout-background-image"
              image={backgroundImage?.gatsbyImageData}
              alt={backgroundImage?.alt || 'background-image'}
            />
            <Footer id="footer-navigation" navs={navData} className="big-footer" />
          </LeftSection>
          <RightSection id="layout-rightsection-wrapper" style={rightSectionStyle}>
            <div>
              {!omitClose && (
                <CloseButton onClick={handleClosePage} id="layout-close-button">
                  <CloseIcon alt="close-icon" />
                </CloseButton>
              )}
            </div>
            <ScrollWrap>
              <Logo id="layout-logo" href={process.env.GATSBY_LANDING_PAGES_HOME} alt="logo">
                <GatsbyImage
                  id="layout-logo-image"
                  image={logoBlack?.gatsbyImageData}
                  alt={logoBlack?.alt || 'lmplus_logo'}
                />
              </Logo>
              {currentStep > 0 && <Stepper currentStep={currentStep} totalSteps={stepNumber} />}
              <ContentWrapper>{children}</ContentWrapper>
              <Footer
                id="footer-navigation"
                navs={navData}
                className="small-footer"
                style={{ marginTop: mobileBottomMargin ? `${mobileBottomMargin}px` : 'auto' }}
              />
            </ScrollWrap>
            <Footer id="footer-navigation" navs={navData} className="medium-footer" />
          </RightSection>
        </Wrapper>
      </ThemeProvider>
    </MUIThemeProvider>
  );
};

export default Layout;
