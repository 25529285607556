import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
/* eslint-disable import/no-relative-packages */

import { Product } from '@src/type/Product';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import { addErrorInDatadogRum, isBrowser } from '../../../utils/utilities';
import getPricingPrismicData from '../../../utils/normalizePlanCardPrismicData';
import { createClient, createPublicClient } from '../../../services/client';
import getValidProducts from '../../../graphql/getValidProducts';
import { normalizePlans } from '../../signup/selectPlan/normalizePlansPromotions';
import changeSubscriptionChargifyJs from '../../../graphql/changeSubscriptionChargifyJs';
import { getBillingPeriod } from '../../../utils/currencyPromotions';
import normalizeServerErrorMsg from '../../../utils/normalizeServerErrorMsg';
import SubscriptionManagementLayout from '../../common/subscriptionManagementLayout';
import Button from '../../common/button/Button';
import CurrentPaymentInfo from '../currentPaymentInfo';
import AvailableSubscriptions from '../availableSubscriptions';
import SuccessMsg from '../successMsg';
import Alert from '../../common/alert';
import { Wrapper, TermsCondition, SelectedPlanName } from './style';
import { redirectToMyAccount } from '../../../../../../src/utils/utilities';

const ChangeSubscriptionNoneTiering = ({ pageData, location }) => {
  const { chargifyPaymentProfile, paymentMethod, lmodSubscription } = useContext(userContext);
  const currentPaymentInfo = chargifyPaymentProfile || paymentMethod;

  const [availablePlans, setAvailablePlans] = useState<Array<Product> | null>(null);
  const [countryCode, setCountryCode] = useState<string>('');
  const [selectedPlan, setSelectedPlan] = useState<Product>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selectedPlanDisplayName, setSelectedPlanDisplayName] = useState<string>('');
  const [changeSubscriptionSuccess, setChangeSubscriptionSuccess] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');

  const availablePlansRef = useRef(null);

  const urlParams = new URLSearchParams(location.search);
  const isFromApp = urlParams.get('from') === 'app';
  const isFromEmail = urlParams.get('from') === 'email';
  const preSelectedProduct = location?.state?.preSelectedProduct;
  const isMobile = isBrowser && window.matchMedia('only screen and (max-width: 1024px)').matches;
  // if the user is from APP link or from email link on mobile, should reverse plan order
  // and scroll to the bottom of the page
  const reversePlanOrder = isFromApp || (isFromEmail && isMobile);

  const prismicData = pageData?.prismicChangeSubscriptionPage?.data;
  const planCardPrismicBody = pageData?.prismicLesMillsPlusCommonLandingPage?.data?.body;
  const planCardPrismicData = getPricingPrismicData(planCardPrismicBody || []);

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    title,
    terms_and_conditions,
    cancel_anytime,
    submit_switch_button_text,
    submit_stay_button_text,
    success_message_title,
    success_message_description,
    available_subscription_title,
  } = prismicData;

  useEffect(() => {
    try {
      const getDefaultSelectedPlan = (displayPlans: Array<Product>) => {
        let defaultSelectedPlan = reversePlanOrder
          ? displayPlans[displayPlans.length - 1]
          : displayPlans[0];

        if (preSelectedProduct) {
          // eslint-disable-next-line prefer-destructuring
          defaultSelectedPlan = displayPlans.filter(
            (plan) => plan.product_handle === preSelectedProduct
          )[0];
        }
        return defaultSelectedPlan;
      };
      const getValidProductsQuery = async () => {
        const prices = await createPublicClient.query({
          query: getValidProducts,
          variables: {},
        });
        const normalizedPlans = normalizePlans(prices, 'normal');
        const displayPlans = reversePlanOrder ? normalizedPlans : normalizedPlans.reverse();
        setCountryCode(prices.data.getHeaders.countryCode);
        setAvailablePlans(displayPlans);
        setSelectedPlan(getDefaultSelectedPlan(displayPlans));
      };
      getValidProductsQuery();
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  }, [preSelectedProduct, reversePlanOrder]);

  useEffect(() => {
    if (availablePlansRef?.current) {
      availablePlansRef?.current?.scrollIntoView({
        block: reversePlanOrder ? 'end' : 'start',
        behavior: 'smooth',
      });
    }
  }, [availablePlans, reversePlanOrder]);

  const getPlanDisplayName = useCallback(() => {
    const billingPeriod = getBillingPeriod(selectedPlan.interval, {
      quarterly: '3-Monthly',
      yearly: 'Annual',
      monthly: 'Monthly',
      biyearly: 'Bi-Yearly',
    });
    setSelectedPlanDisplayName(billingPeriod);
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedPlan) {
      getPlanDisplayName();
    }
  }, [getPlanDisplayName, selectedPlan]);

  const handleChangeSubscription = async () => {
    try {
      setIsProcessing(true);
      const res = await createClient.mutate({
        mutation: changeSubscriptionChargifyJs,
        variables: {
          product_handle: selectedPlan?.id,
        },
      });
      if (res?.data?.changeSubscriptionChargifyJs) {
        setIsProcessing(false);
        setChangeSubscriptionSuccess(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    } catch (error) {
      setServerError(normalizeServerErrorMsg(error));
      addErrorInDatadogRum(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const termsAndConditions = terms_and_conditions.split('{product-handle}');

  const submitBtnText =
    lmodSubscription?.product_handle === selectedPlan?.product_handle
      ? submit_stay_button_text.replace('{plan-name}', selectedPlanDisplayName)
      : submit_switch_button_text.replace('{plan-name}', selectedPlanDisplayName);

  return (
    <SubscriptionManagementLayout
      title={changeSubscriptionSuccess ? '' : title}
      backLink={!isFromApp ? { handleClick: () => redirectToMyAccount() } : null}
      subTitle={!changeSubscriptionSuccess ? available_subscription_title : ''}
      theme="white"
      metaData={{
        title: 'Change Subscription',
      }}
    >
      {changeSubscriptionSuccess && (
        <SuccessMsg title={success_message_title} description={success_message_description} />
      )}
      {serverError && <Alert content={serverError} />}
      {!changeSubscriptionSuccess && (
        <Wrapper ref={availablePlansRef}>
          {availablePlans && lmodSubscription?.product_handle && (
            <AvailableSubscriptions
              prismicData={prismicData}
              planCardPrismicData={planCardPrismicData}
              countryCode={countryCode}
              availablePlans={availablePlans}
              currentProductHandle={lmodSubscription?.product_handle}
              getSelectedPlan={(plan) => {
                setSelectedPlan(plan);
              }}
              selectedPlan={selectedPlan}
            />
          )}
          {currentPaymentInfo && (
            <CurrentPaymentInfo
              prismicData={prismicData}
              paymentInfo={currentPaymentInfo}
              selectedProductHandle={selectedPlan?.product_handle}
              urlParam={urlParams.toString()}
            />
          )}
          <TermsCondition>
            <p>
              {termsAndConditions[0]}
              <SelectedPlanName>{selectedPlanDisplayName}</SelectedPlanName>
              {termsAndConditions[1]}
            </p>
            <p>{cancel_anytime}</p>
          </TermsCondition>
          <Button loading={isProcessing} onClick={handleChangeSubscription}>
            <span>{submitBtnText}</span>
          </Button>
        </Wrapper>
      )}
    </SubscriptionManagementLayout>
  );
};

export default ChangeSubscriptionNoneTiering;
