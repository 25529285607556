export const SUBSCRIPTION_STATES = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  ON_DELAYED_CANCELLATION: 'on-delayed-cancellation',
  CANCELED: 'canceled',
  PROCESSING: 'processing',
  EXPIRED: 'expired',
  ON_HOLD: 'on_hold',
  PAST_DUE: 'past_due',
  SOFT_FAILUE: 'soft_failure',
  TRIAL_ENDED: 'trial_ended',
  ASSESSING: 'assessing',
  FAILED_TO_CREATE: 'failed_to_create',
  PAUSED: 'paused',
  PENDING: 'pending',
  SUSPENDED: 'suspended',
  UNPAID: 'unpaid',
  FAILED: 'failed',
} as const;

export type SubscriptionState = (typeof SUBSCRIPTION_STATES)[keyof typeof SUBSCRIPTION_STATES];
