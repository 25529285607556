/* eslint max-len: ["error", { "code": 120 }] */
import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/common/layout';
import SelectPlanPromotions from '../components/signup/selectPlan/SelectPlanPromotions';
import SelectPlanTiering from '../components/signup/selectPlan/SelectPlanTiering';
import { normalizePriceData, normalizeTieringPriceData } from '../utils/normalizePriceData';
import { getDiscountInfo, getLocalStorage } from '../utils/utilities';

import { userContext } from '../../../gatsby-theme-engagement/src/context';
import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';
import Payment from '../components/signup/payment/Payment';
import useSetUserInfo from '../hooks/useSetUserInfo';
import { CHARGIFY_SCRIPT } from '../components/signup/payment/constants';
import useHandleSelectPlan from '../hooks/useHandleSelectPlan';
import useDisplayRecaptchaBadge from '../hooks/useDisplayRecaptchaBadge';
import useGetCountryCode from '../hooks/useGetCountryCode';

const SelectPlanTemplate = ({ data, pageContext, location }) => {
  const {
    prismicSignUpLayout,
    prismicPromotionLandingPage,
    prismicLesMillsPlusCommonLandingPage,
    prismicSignUpLeavePageDialog,
    prismicCreateAccountPage,
  } = data;
  const { countryCode } = useGetCountryCode();
  const { isEntitledToFreeTrial } = useContext(userContext);
  const countryOverridesData = prismicPromotionLandingPage?.data?.country_overrides;

  useDisplayRecaptchaBadge();

  const showTieringPlan = getLocalStorage('isTieringEnabled') === 'true';

  const normalizedData = showTieringPlan
    ? normalizeTieringPriceData(
        prismicPromotionLandingPage,
        prismicLesMillsPlusCommonLandingPage,
        countryCode
      )
    : normalizePriceData(prismicPromotionLandingPage, prismicLesMillsPlusCommonLandingPage);

  const { userValidated, userInfo } = useSetUserInfo();
  let discountInfo = getDiscountInfo();
  discountInfo = discountInfo?.type === 'promotionCode' ? discountInfo : null;

  const { selectPlanScrollRef, selectedPlan, handleSelectPlan } = useHandleSelectPlan(discountInfo);

  if (!normalizedData) {
    return <div />;
  }

  const countriesData = prismicSignUpLayout.data?.body1[0];
  const statesData = prismicSignUpLayout.data?.body2;

  const SelectPlan = showTieringPlan ? SelectPlanTiering : SelectPlanPromotions;

  const voucherContent = prismicPromotionLandingPage.data.addon_content;
  const { lang } = pageContext;

  return (
    <Layout
      data={prismicSignUpLayout.data}
      currentStep={3}
      lang={lang}
      pathName="/signup/select-plan"
      omitClose
      scripts={[CHARGIFY_SCRIPT]}
      rightSectionStyle={{ paddingTop: '28px' }}
      title="Sign Up - Select Plan"
    >
      <SelectPlan
        pageData={normalizedData}
        location={location}
        voucherContent={voucherContent}
        pageContext={pageContext}
        discountInfo={discountInfo}
        leavePageDialogContent={prismicSignUpLeavePageDialog?.data}
        countryOverridesData={countryOverridesData}
        isWinback={!isEntitledToFreeTrial}
        selectedPlan={selectedPlan}
        onSelectPlan={handleSelectPlan}
      />
      {userValidated && (
        <div ref={selectPlanScrollRef} style={{ marginTop: '40px' }}>
          <Payment
            selectedPlan={selectedPlan}
            lang={pageContext?.lang}
            countriesData={countriesData}
            statesData={statesData}
            discountInfo={discountInfo}
            voucherVerificationMessageContent={prismicCreateAccountPage?.data}
            userInfo={userInfo}
          />
        </div>
      )}
    </Layout>
  );
};
export default withPrismicPreview(withPrivateRoute(SelectPlanTemplate, true));

export const query2 = graphql`
  query ($lang: String, $uid: String) {
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicSignUpLayoutDataBody1Countries {
            items {
              country_code {
                text
              }
              country_name {
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicSignUpLayoutDataBody2States {
            primary {
              country_name {
                text
              }
              country_code {
                text
              }
            }
            items {
              state_name {
                text
              }
              state_code {
                text
              }
              state_short_name {
                text
              }
            }
          }
        }
      }
    }
    prismicLesMillsPlusCommonLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicLesMillsPlusCommonLandingPageDataBodyPricing {
            slice_type
            primary {
              annually_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              pricing_title
              winback_pricing_title
              no_free_trial_text
              three_monthly_text
              best_value
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
              first_payment
              next
              oneoff_first_payment
              oneoff_monthly_billed
              oneoff_trial_text
            }
          }
          ... on PrismicLesMillsPlusCommonLandingPageDataBodyTieringPricing {
            slice_type
            items {
              benefit_item_icon
              benefit_item_text
              benefit_item_type
              children_content
              tooltip_children_content
              children_title
              tier_type
            }
            primary {
              annually_badge
              year
              annually_text
              base_tier_monthly_promo
              base_tier_description
              badge_text
              base_tier_annually_promo
              base_tier_monthly_subscribe_btn_copy
              base_tier_title
              best_value
              best_value_option
              cancellation_text
              billed_text
              winback_pricing_title
              switch_default_value
              show_promo_on_premium_tier_monthly_plan
              show_promo_on_premium_tier_annually_plan
              show_promo_on_base_tier_monthly_plan
              show_promo_on_base_tier_annually_plan
              pricing_title
              pricing_subtitle
              pricing_disclaimer
              pricing_cta_label
              pricing_cta_button
              premium_tier_title
              premium_tier_monthly_subscribe_btn_copy
              premium_tier_monthly_promo
              premium_tier_description
              premium_tier_annually_promo
              change_plan
              day
              days
              free_trial_text
              month
              monthly_badge
              monthly_text
              plan_annually_disclaimer
              no_trial_text
              months
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
            }
          }
        }
      }
      _previewable
      lang
    }
    prismicPromotionLandingPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPromotionLandingPageDataBodyPricing {
            slice_type
            primary {
              annually_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              winback_pricing_title
              no_free_trial_text
              pricing_title
              three_monthly_text
              best_value
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
            }
          }
          ... on PrismicPromotionLandingPageDataBodyTieringPricing {
            slice_type
            items {
              benefit_item_icon
              benefit_item_text
              benefit_item_type
              children_content
              tooltip_children_content
              children_title
              tier_type
            }
            primary {
              annually_badge
              year
              annually_text
              base_tier_monthly_promo
              base_tier_description
              badge_text
              base_tier_annually_promo
              base_tier_monthly_subscribe_btn_copy
              base_tier_title
              best_value
              best_value_option
              cancellation_text
              billed_text
              winback_pricing_title
              switch_default_value
              show_promo_on_premium_tier_monthly_plan
              show_promo_on_premium_tier_annually_plan
              show_promo_on_base_tier_monthly_plan
              show_promo_on_base_tier_annually_plan
              pricing_title
              pricing_subtitle
              pricing_disclaimer
              pricing_cta_label
              pricing_cta_button
              premium_tier_title
              premium_tier_monthly_subscribe_btn_copy
              premium_tier_monthly_promo
              premium_tier_description
              premium_tier_annually_promo
              change_plan
              day
              days
              free_trial_text
              month
              monthly_badge
              monthly_text
              plan_annually_disclaimer
              no_trial_text
              months
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
            }
          }
        }

        meta_title
        meta_description
        addon_content {
          addon_title
          addon_description
          country
          addon_price_point
          addon_logo {
            alt
            url
          }
        }
        country_overrides {
          hero_title
          country
          show_total_price
        }
      }
      _previewable
      lang
    }
    prismicSignUpLeavePageDialog(lang: { eq: $lang }) {
      data {
        title
        description
        stay_btn_text
        leave_btn_text
      }
    }
    prismicCreateAccountPage(lang: { eq: $lang }) {
      _previewable
      data {
        voucher_success
        voucher_has_been_redeemed
        voucher_is_expired
        voucher_is_invalid
        voucher_is_not_required
        voucher_is_required
        country_code_missing
        bau_landing_page_link
        homepage_link
        customer_support_link
      }
    }
  }
`;
