import { formatDuration } from '@services';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import GET_COLLECTION from '../../../engagementGql/collection';
import { createEngagementClient } from '../../../../../../src/services/client';
import { Early_Tenure_Home_PageBodyEarly_Tenure_Interest_Based_CollectionFields } from '../../../generated/prismicGraphql';

interface Category {
  id: string;
  label: string;
  active: boolean;
}

interface ActiveCategories {
  categories: Category[];
  activeCategory: Category;
}

interface WorkoutType {
  name: string;
  duration: string;
  equipmentIcons: string[];
  backgroundImg: string;
  link: string;
  videoId: string;
}

interface FilterDataType {
  program_name: string;
  program_subtitle: string;
  program_background_image: {
    url: string;
  };
  program_label: string;
  program_link: string;
  collection_id: string;
  workouts: WorkoutType[];
}

export interface FormattedFilterDataType {
  [key: string]: FilterDataType;
}

export const formatCategories = (categoriesSelected: string[]): Category[] =>
  categoriesSelected.map((category) => ({
    label: category,
    id: category,
    active: false,
  }));

export const getActiveProgramCategories = (
  categories: Category[],
  selectedId: string
): ActiveCategories => {
  const updatedCategories = categories.map((category) => ({
    ...category,
    active: category.id === selectedId,
  }));

  return {
    categories: updatedCategories,
    activeCategory: updatedCategories.find((category) => category.active) || updatedCategories[0],
  };
};

export const formatFilterDataFromPrismicAndCollection = async (
  items: Early_Tenure_Home_PageBodyEarly_Tenure_Interest_Based_CollectionFields[],
  selectedCategories: string[]
): Promise<FormattedFilterDataType> => {
  const filterData: FormattedFilterDataType = {};

  const selectedItems = !selectedCategories?.length
    ? items.filter((item) => item.default === true) // if user has no categories selected, show default
    : items.filter((item) => item.option && selectedCategories.includes(item.option));

  await Promise.all(
    selectedItems.map(async (item) => {
      try {
        const { data } = await createEngagementClient.query({
          query: GET_COLLECTION,
          fetchPolicy: 'cache-first',
          variables: {
            id: Number(item.collection_id),
            page: 1,
            perPage: 100,
          },
        });

        if (data?.collection?.items && item.option) {
          filterData[item.option] = {
            program_name: item.program_name || '',
            program_subtitle: item.program_subtitle || '',
            program_background_image: item.program_background_image,
            program_label: item.program_label || '',
            program_link: item.program_link || '',
            collection_id: item.collection_id || '',
            workouts: data.collection.items.map((collectionItem: any) => ({
              name: collectionItem.workout?.workoutName || '',
              duration: formatDuration(collectionItem.workout?.durationInSeconds || 0),
              equipmentIcons: collectionItem.workout?.equipment || [],
              backgroundImg: collectionItem.workout?.imageWide || '',
              link: `/workout/${encodeURIComponent(collectionItem.workout?.workoutName || '')}`,
              videoId: collectionItem.workout?.videoId || '',
            })),
          };
        }
      } catch (error) {
        addErrorInDatadogRum(error);
      }
    })
  );

  return filterData;
};

export type { Category, ActiveCategories, WorkoutType, FilterDataType };
