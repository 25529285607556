import React, { useContext, useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { userContext } from '../../../gatsby-theme-engagement/src/context';
import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';
import useConfirmSubComms from '../hooks/mutation/useConfirmSubComms';
import { addErrorInDatadogRum } from '../utils/utilities';
import Layout from '../components/common/layout';
import DoubleOptIn from '../components/doubleOptIn';
import ProcessingMsg from '../components/common/ProcessingMsg/ProcessingMsg';

const DoubleOptInTemplate = ({
  data: {
    prismicDoubleOptInPage: { lang, data: doubleOptInPageData },
    prismicSignUpLayout: { data: layout },
  },
}: PageProps<{
  prismicDoubleOptInPage: Queries.PrismicDoubleOptInPage;
  prismicSignUpLayout: Queries.PrismicSignUpLayout;
}>) => {
  const { subCommsConfirmed, refetchUser } = useContext(userContext);

  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState({
    title: doubleOptInPageData?.success_title,
    text: doubleOptInPageData?.success_text,
    button: {
      text: doubleOptInPageData?.success_button_text,
      link: doubleOptInPageData?.success_button_link?.url,
    },
  });

  const [handleConfirmSubComms] = useConfirmSubComms({
    onError: (err) => {
      addErrorInDatadogRum(err);
      setContent({
        title: doubleOptInPageData?.error_title,
        text: doubleOptInPageData?.invalid_ticket_error,
        button: {
          text: doubleOptInPageData?.error_button_text,
          link: doubleOptInPageData?.success_button_link?.url,
        },
      });
      setIsLoading(false);
    },
    onCompleted: () => {
      refetchUser && refetchUser();
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (subCommsConfirmed) {
      setIsLoading(false);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const ticket = urlParams.get('ticket');

    if (!ticket) {
      setContent({
        title: doubleOptInPageData?.error_title,
        text: doubleOptInPageData?.invalid_ticket_error,
        button: {
          text: doubleOptInPageData?.error_button_text,
          link: doubleOptInPageData?.error_button_link?.url,
        },
      });
      setIsLoading(false);
      return;
    }

    handleConfirmSubComms({ variables: { ticket } });
  }, [
    doubleOptInPageData?.invalid_ticket_error,
    doubleOptInPageData?.error_title,
    handleConfirmSubComms,
    subCommsConfirmed,
    doubleOptInPageData?.error_button_text,
    doubleOptInPageData?.error_button_link?.url,
  ]);

  return (
    <Layout
      // TODO: types are not compatible due to the data prop vs prismic types
      data={layout as any}
      omitClose
      lang={lang}
      pathName="/account/confirm-email-preference"
      currentStep={0}
      scripts={[]}
    >
      {isLoading ? (
        <ProcessingMsg loadingMsg={doubleOptInPageData?.loading_message || ''} />
      ) : (
        <DoubleOptIn title={content.title} message={content.text} button={content.button} />
      )}
    </Layout>
  );
};

export default withPrivateRoute(DoubleOptInTemplate, true);

export const query = graphql`
  query ($lang: String) {
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }

    prismicDoubleOptInPage(lang: { eq: $lang }) {
      lang
      data {
        loading_message
        success_title
        success_text
        error_title
        invalid_ticket_error
        success_button_text
        success_button_link {
          url
        }
        error_button_text
        error_button_link {
          url
        }
      }
    }
  }
`;
