import React, { FC, useState, useRef, useEffect } from 'react';
import { VideoCard } from '@lesmills-international/components';
import { contentClickTracking, linkClickTracking } from '@src/utils';
import { Event } from '@src/type';
import { useWindowSize } from '@hooks';
import Carousel from '@components/atoms/carousel/Carousel';
import { getSlideSpace, getSlidesPerView, getSlidesPerGroup, formatDuration } from '@services';
import { Link } from 'gatsby';
import { SlicePlaceholder } from '@components/atoms';
import { shuffle } from 'lodash';
import {
  TitleWrapper,
  Title,
  Subtitle,
  CarouselWrapper,
  ContentWrapper,
  ViewMoreButton,
  HeaderWrapper,
} from './styles';
import GET_COLLECTION from '../../../engagementGql/collection';

import { Early_Tenure_Home_Screen_AppBodyWorkout_Carousel } from '../../../generated/prismicGraphql';
import { createEngagementClient } from '../../../../../../src/services/client';
import { addErrorInDatadogRum } from '../../../utils/utilities';

interface WorkoutCarouselContent extends Early_Tenure_Home_Screen_AppBodyWorkout_Carousel {
  primary: {
    title: string;
    subtitle?: string;
    fitnessLevel: string;
  };
  items: Workout[];
}

interface Workout {
  collection_id: string;
  subtitle: string;
  title: string[];
  view_more_text: string;
}

interface WorkoutItem {
  title: string;
  description: string;
  link: string;
  duration: string;
  equipmentIcons: string[];
  backgroundImg: string;
  name: string;
}
interface WorkoutCollections {
  [collectionId: string]: JSX.Element[];
}

interface Props {
  content: WorkoutCarouselContent;
}

const getWorkoutsFromCollection = async (collectionId: string): Promise<WorkoutItem[]> => {
  try {
    const { data } = await createEngagementClient.query({
      query: GET_COLLECTION,
      fetchPolicy: 'cache-first',
      variables: {
        id: Number(collectionId),
        page: 1,
        perPage: 100,
      },
    });

    if (data?.collection?.items) {
      return data.collection.items.map((collectionItem: any) => ({
        name: collectionItem.workout?.workoutName || '',
        duration: formatDuration(collectionItem.workout?.durationInSeconds || 0),
        equipmentIcons: collectionItem.workout?.equipment || [],
        backgroundImg: collectionItem.workout?.imageWide || '',
        link: `/workout/${encodeURIComponent(collectionItem.workout?.workoutName || '')}`,
        videoId: collectionItem.workout?.videoId || '',
      }));
    }
  } catch (error) {
    addErrorInDatadogRum(error);
  }

  return [];
};

const EarlytenureWorkoutCarousel: FC<Props> = ({ content }) => {
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState(3);
  const [carouselSlidesPerGroup, setCarouselSlidesPerGroup] = useState(1);
  const [carouselSlidesSpace, setCarouselSlidesSpace] = useState(8);
  const [workoutCollections, setWorkoutCollections] = useState<WorkoutCollections>({});
  const [shuffledWorkouts, setShuffledWorkouts] = useState<{ [key: string]: WorkoutItem[] }>({});
  const [loading, setLoading] = useState(false);

  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();

  useEffect(() => {
    const fetchWorkouts = async () => {
      setLoading(true);
      const collections: { [key: string]: WorkoutItem[] } = {};

      await Promise.all(
        content.items.map(async (item) => {
          const workouts = await getWorkoutsFromCollection(item.collection_id);
          collections[item.collection_id] = shuffle(workouts);
          collections;
        })
      );

      setShuffledWorkouts(collections);
      setLoading(false);
    };

    fetchWorkouts();
  }, [content.items]);

  useEffect(() => {
    if (carouselRef.current && shuffledWorkouts) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = getSlidesPerView(carouselWrapperWidth);
      const slidesPerGroup = getSlidesPerGroup(carouselWrapperWidth);
      const slideSpace = getSlideSpace(carouselWrapperWidth);

      const collections: WorkoutCollections = {};

      Object.entries(shuffledWorkouts).forEach(([collectionId, workouts]) => {
        const matchingItem = content.items.find((item) => item.collection_id === collectionId);
        const workoutCardArray = workouts.map((workout, index) => (
          <VideoCard
            key={index}
            name={workout.name}
            duration={workout.duration}
            equipmentIcons={workout.equipmentIcons}
            backgroundImg={workout.backgroundImg}
            link={`/workout/${encodeURIComponent(workout.name)}`}
            aspectRatio="landscape"
            onClick={() => {
              contentClickTracking({
                contentListTitle: matchingItem?.title,
                contentListIndex: index + 1,
                contentTitle: workout.name,
                contentType: 'video',
              });
            }}
          />
        ));

        if (workoutCardArray.length < slidesPerView) {
          for (let i = 0; i < slidesPerView - workoutCardArray.length; i++) {
            workoutCardArray.push(<div key={`empty-${i}`} />);
          }
        }

        collections[collectionId] = workoutCardArray;
      });

      setWorkoutCollections(collections);
      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlidesPerGroup(slidesPerGroup);
      setCarouselSlidesSpace(slideSpace);
    }
  }, [windowInnerWidth, shuffledWorkouts, content.items]);

  return (
    <>
      {content.items.map((item) => (
        <ContentWrapper>
          <HeaderWrapper>
            <TitleWrapper>
              <Title>{item.title}</Title>
              <Subtitle>{item.subtitle}</Subtitle>
            </TitleWrapper>
            <Link to="/explore">
              <ViewMoreButton
                onClick={() => {
                  linkClickTracking({
                    eventName: Event.CLICK_TO_EXPLORE,
                    link: item.view_more_text,
                    contentListTitle: item.title,
                  });
                }}
              >
                {item.view_more_text}
              </ViewMoreButton>
            </Link>
          </HeaderWrapper>
          <CarouselWrapper ref={carouselRef} noRightPadding>
            {loading ? (
              <SlicePlaceholder />
            ) : (
              <Carousel
                items={workoutCollections[item.collection_id] || []}
                slidesPerView={carouselSlidesPerView}
                slidesPerGroup={carouselSlidesPerGroup}
                spaceBetween={carouselSlidesSpace}
                className="home--carousel--wrapper"
                alwaysShowArrowButton
              />
            )}
          </CarouselWrapper>
        </ContentWrapper>
      ))}
    </>
  );
};

export default EarlytenureWorkoutCarousel;
