import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import confirmSubComms from '../../graphql/confirmSubComms';

interface ConfirmSubCommsVariables {
  ticket: string;
}

const useConfirmSubComms = (
  options?: MutationHookOptions<boolean, ConfirmSubCommsVariables>
): MutationTuple<boolean, ConfirmSubCommsVariables> =>
  useMutation<boolean, ConfirmSubCommsVariables>(confirmSubComms, options);

export default useConfirmSubComms;
