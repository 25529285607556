import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, navigate, PageProps } from 'gatsby';
import styled from 'styled-components';

/* eslint-disable import/no-relative-packages */
import withPrivateRoute from '../../../gatsby-theme-engagement/src/components/molecules/withPrivateRoute';

import SubscriptionManagementLayout from '../components/common/subscriptionManagementLayout';
import Payment from '../components/updatePaymentDetails';
import { CHARGIFY_SCRIPT } from '../components/signup/payment/constants';
import useDisplayRecaptchaBadge from '../hooks/useDisplayRecaptchaBadge';
import useGetCountryCode from '../hooks/useGetCountryCode';
import ROUTES from '../../../../src/constants/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 140px;
`;

const UpdatePaymentDetailsTemplate: FC<PageProps<Queries.GetUpdatePaymentDetailsQuery>> = ({
  data,
  pageContext,
  location,
}) => {
  const { countryCode } = useGetCountryCode();

  const { prismicUpdatePaymentDetailsPage, prismicSignUpLayout } = data;
  const countriesData = prismicSignUpLayout?.data?.body1[0];
  const statesData = prismicSignUpLayout?.data?.body2;
  /* eslint-disable @typescript-eslint/naming-convention */
  const paymentPrismicData = prismicUpdatePaymentDetailsPage?.data;

  // TODO clean backButtonLink logic
  const backBtnUrl =
    location?.state?.from === 'change-subscription'
      ? location?.state?.urlSearchParam
        ? `${ROUTES(pageContext?.lang).SUBSCRIPTION}?${location?.state?.urlSearchParam}`
        : `${ROUTES(pageContext?.lang).SUBSCRIPTION}`
      : process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true'
      ? ROUTES().ACCOUNT
      : process.env.GATSBY_MY_ACCOUNT_URL!;

  useDisplayRecaptchaBadge();

  const handleBackLinkClick = () => {
    navigate(backBtnUrl, {
      state: {
        preSelectedProduct: location?.state?.preSelectedProduct,
      },
    });
  };

  return (
    <SubscriptionManagementLayout
      scripts={[CHARGIFY_SCRIPT]}
      title={paymentPrismicData?.title}
      backLink={{
        handleClick: () => handleBackLinkClick(),
      }}
      size="narrow"
      metaData={{
        title: paymentPrismicData?.meta_title,
        description: paymentPrismicData?.meta_description,
      }}
    >
      <Wrapper>
        <Payment
          lang={pageContext?.lang}
          countryCode={countryCode}
          countriesData={countriesData}
          statesData={statesData}
          prePage={backBtnUrl}
          preSelectedProduct={location?.state?.preSelectedProduct}
        />
      </Wrapper>
    </SubscriptionManagementLayout>
  );
};

export default withPrismicPreview(withPrivateRoute(UpdatePaymentDetailsTemplate));

export const query = graphql`
  query GetUpdatePaymentDetails($lang: String!) {
    prismicUpdatePaymentDetailsPage(lang: { eq: $lang }) {
      _previewable
      data {
        title
        save_button
        new_payment_title
        back_button_text
        back_to_my_account_url
        meta_title
        meta_description
      }
    }
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicSignUpLayoutDataBody1Countries {
            items {
              country_code {
                text
              }
              country_name {
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicSignUpLayoutDataBody2States {
            primary {
              country_name {
                text
              }
              country_code {
                text
              }
            }
            items {
              state_name {
                text
              }
              state_code {
                text
              }
              state_short_name {
                text
              }
            }
          }
        }
      }
    }
  }
`;
