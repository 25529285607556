/* eslint max-len: ["error", { "code": 120 }] */
import React, { useContext, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/common/layout';
import SelectPlanTiering from '../components/signup/selectPlan/SelectPlanTiering';
import SelectPlanPromotions from '../components/signup/selectPlan/SelectPlanPromotions';

import { normalizePriceData, normalizeTieringPriceData } from '../utils/normalizePriceData';
import { getDiscountInfo, getLocalStorage, setLocalStorage } from '../utils/utilities';
import handleSelectPlanRedirection from '../utils/selectPlanRedirection';
import { userContext } from '../../../gatsby-theme-engagement/src/context';
import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';
import { CHARGIFY_SCRIPT } from '../components/signup/payment/constants';
import useSetUserInfo from '../hooks/useSetUserInfo';
import Payment from '../components/signup/payment/Payment';
import useHandleSelectPlan from '../hooks/useHandleSelectPlan';
import useDisplayRecaptchaBadge from '../hooks/useDisplayRecaptchaBadge';
import useGetCountryCode from '../hooks/useGetCountryCode';

const SelectPlanTemplate = ({ data, pageContext, location }) => {
  const {
    prismicSignUpLayout,
    prismicLesMillsPlusCommonLandingPage,
    prismicLesMillsPlusTieringLandingPage,
    prismicLesMillsPlusMainLandingPage,
    prismicSignUpLeavePageDialog,
    prismicCreateAccountPage,
  } = data;

  const { countryCode } = useGetCountryCode();
  const { isEntitledToFreeTrial } = useContext(userContext);
  const discountInfo = getDiscountInfo();

  const { userValidated, userInfo } = useSetUserInfo();

  const { selectPlanScrollRef, selectedPlan, handleSelectPlan } = useHandleSelectPlan(discountInfo);

  const isTieringEnabled = JSON.parse(getLocalStorage('isTieringEnabled') || 'true');

  const normalizedData = isTieringEnabled
    ? normalizeTieringPriceData(
        prismicLesMillsPlusTieringLandingPage,
        prismicLesMillsPlusCommonLandingPage,
        countryCode
      )
    : normalizePriceData(prismicLesMillsPlusMainLandingPage, prismicLesMillsPlusCommonLandingPage);

  const { lang } = pageContext;

  const promotionCode = discountInfo?.data?.promotionCode;

  const PricingCard = isTieringEnabled ? SelectPlanTiering : SelectPlanPromotions;

  useDisplayRecaptchaBadge();

  useEffect(() => {
    const discountInfoType = discountInfo?.type;
    const selectPlanURL = handleSelectPlanRedirection({
      discountInfoType,
      promotionCode,
      lang,
      isDefaultNavigatable: false,
      isFromBAU: getLocalStorage('isFromBAU') === 'true',
    });

    if (selectPlanURL) {
      navigate(selectPlanURL);
      return;
    }

    setLocalStorage('isTieringEnabled', isTieringEnabled);
  }, [discountInfo?.type, promotionCode, lang, isTieringEnabled]);

  if (!normalizedData) {
    return <div />;
  }

  const countriesData = prismicSignUpLayout.data?.body1[0];
  const statesData = prismicSignUpLayout.data?.body2;

  return (
    <Layout
      data={prismicSignUpLayout.data}
      currentStep={3}
      lang={lang}
      pathName="/signup/select-plan"
      omitClose
      scripts={[CHARGIFY_SCRIPT]}
      rightSectionStyle={{ paddingTop: '28px' }}
      title="Sign Up - Select Plan"
    >
      <PricingCard
        isWinback={!isEntitledToFreeTrial}
        pageData={normalizedData}
        location={location}
        onSelectPlan={handleSelectPlan}
        pageContext={pageContext}
        discountInfo={discountInfo}
        leavePageDialogContent={prismicSignUpLeavePageDialog?.data}
        selectedPlan={selectedPlan}
      />

      {userValidated && (
        <div ref={selectPlanScrollRef} style={{ marginTop: '40px' }}>
          <Payment
            selectedPlan={selectedPlan}
            lang={pageContext?.lang}
            countriesData={countriesData}
            statesData={statesData}
            discountInfo={discountInfo}
            voucherVerificationMessageContent={prismicCreateAccountPage?.data}
            userInfo={userInfo}
          />
        </div>
      )}
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(SelectPlanTemplate, true));

export const query = graphql`
  query ($lang: String, $uid: String) {
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicSignUpLayoutDataBody1Countries {
            items {
              country_code {
                text
              }
              country_name {
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicSignUpLayoutDataBody2States {
            primary {
              country_name {
                text
              }
              country_code {
                text
              }
            }
            items {
              state_name {
                text
              }
              state_code {
                text
              }
              state_short_name {
                text
              }
            }
          }
        }
      }
    }
    prismicLesMillsPlusCommonLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicLesMillsPlusCommonLandingPageDataBodyPricing {
            slice_type
            primary {
              annually_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              pricing_title
              winback_pricing_title
              no_free_trial_text
              three_monthly_text
              best_value
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
              first_payment
              next
              oneoff_first_payment
              oneoff_monthly_billed
              oneoff_trial_text
            }
          }
          ... on PrismicLesMillsPlusCommonLandingPageDataBodyTieringPricing {
            slice_type
            primary {
              annually_text
              three_monthly_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              pricing_title
              best_value
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
              base_tier_title
              premium_tier_title
              base_tier_description
              premium_tier_description
              base_tier_monthly_promo
              base_tier_trimonthly_promo
              base_tier_annually_promo
              premium_tier_monthly_promo
              premium_tier_trimonthly_promo
              premium_tier_annually_promo
              switch_default_value
              monthly_badge
              trimonthly_badge
              annually_badge
              badge_text
              best_value_option
            }
            items {
              tier_type
              benefit_item_text
              benefit_item_icon
              benefit_item_type
              children_content
              tooltip_children_content
              children_title
            }
          }
        }
      }
      _previewable
      lang
    }
    prismicLesMillsPlusMainLandingPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicLesMillsPlusMainLandingPageDataBodyPricing {
            slice_type
            primary {
              annually_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              pricing_title
              winback_pricing_title
              no_free_trial_text
              three_monthly_text
              best_value
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
            }
          }
        }

        meta_title
        meta_description
      }
      _previewable
      lang
    }
    prismicLesMillsPlusTieringLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicLesMillsPlusTieringLandingPageDataBodyTieringPricing {
            slice_type
            primary {
              annually_text
              three_monthly_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              pricing_title
              best_value
              best_value_option
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
              base_tier_title
              premium_tier_title
              base_tier_description
              premium_tier_description
              base_tier_monthly_promo
              base_tier_trimonthly_promo
              base_tier_annually_promo
              premium_tier_monthly_promo
              premium_tier_trimonthly_promo
              premium_tier_annually_promo
            }
            items {
              benefit_item_icon
              benefit_item_text
              benefit_item_type
              children_content
              tooltip_children_content
              children_title
              tier_type
            }
          }
        }
        bau_promo_group {
          country
          show_total_price
          promotion
          promo_code
          show_promo_on_base_tier_monthly_plan
          show_promo_on_base_tier_annually_plan
          show_promo_on_premium_tier_monthly_plan
          show_promo_on_premium_tier_annually_plan
          base_tier_monthly_plan_promo_content
          base_tier_annually_plan_promo_content
          premium_tier_monthly_plan_promo_content
          premium_tier_annually_plan_promo_content
        }
        addon_content {
          addon_title
          addon_description
          country
          addon_price_point
          addon_logo {
            alt
            url
          }
        }
      }
      _previewable
      lang
    }
    prismicSignUpLeavePageDialog(lang: { eq: $lang }) {
      data {
        title
        description
        stay_btn_text
        leave_btn_text
      }
    }
    prismicCreateAccountPage(lang: { eq: $lang }) {
      _previewable
      data {
        voucher_success
        voucher_has_been_redeemed
        voucher_is_expired
        voucher_is_invalid
        voucher_is_not_required
        voucher_is_required
        country_code_missing
        bau_landing_page_link
        homepage_link
        customer_support_link
      }
    }
  }
`;
