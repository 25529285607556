import React, { FC, useContext, useEffect, useState } from 'react';
import { PageProps, navigate } from 'gatsby';
import styled from 'styled-components';
import { COLORS } from '@themes';
import { Button } from '@lesmills-international/components';
import Alert from '@mui/material/Alert';

import { withPrivateRoute, Layout } from '@components/molecules';
import SliceZone from '@components/sliceZone';
import { userContext } from '@context';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { ReactComponent as SyncIcon } from '@assets/icons/sync.svg';
import { TIERS } from '@constants';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import PageName from '../../../types/pageName';
import { createEngagementClient, createClient } from '../../../../../../src/services/client';
import { MIGRATE_PLAN, END_PLAN_LEGACY } from '../../../engagementGql/plans';
import { addErrorInDatadogRum } from '../../../utils/utilities';

import Container from './style';
import { ScheduledWorkout } from '../../../hooks/useMyScheduledWorkouts';

const HomepageWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
`;

const DarkAlert = styled(Alert)`
  margin: 48px 64px 0;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 28px 12px 0;
  }
  && {
    background-color: ${COLORS.neoBlack};
    color: ${COLORS.white};
    border-radius: 8px;
    padding: 16px;
  }
`;

const Title = styled.div`
  font-family: 'Inter SemiBold';
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const Content = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  margin-left: 32px;
  width: 85%;
`;
const ButtonsContainer = styled.div`
  margin-right: 12px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 12px;
`;

const CustomAlert = () => {
  const { hasLegacyPlan } = useContext(userContext);

  const { rtPcb } = useFlags();

  const [visible, setVisible] = useState(hasLegacyPlan && rtPcb);

  const handleClose = () => {
    setVisible(false);
  };

  const endPlan = async () => {
    handleClose();

    try {
      await createClient.mutate({
        mutation: END_PLAN_LEGACY,
      });
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  };

  const migratePlan = async () => {
    handleClose();

    try {
      await createEngagementClient.mutate({
        mutation: MIGRATE_PLAN,
      });
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  };

  if (!visible) return null;

  return (
    <DarkAlert
      icon={false}
      action={
        <ButtonsContainer direction="row" spacing={1}>
          <Button style={{ color: COLORS.errorText }} variant="text" onClick={endPlan}>
            End plan
          </Button>
          <Button ctaButton color="lesmillsGreen" onClick={migratePlan}>
            Sync now
          </Button>
        </ButtonsContainer>
      }
    >
      <Title>
        <SyncIcon />
        Keep Your Fitness on Track
      </Title>
      <Content>
        You can now add your active plans to My Schedule. Select <strong>SYNC NOW</strong> to
        continue your current plan and move your remaining workouts to My Schedule in the LES MILLS+
        app. To end the plan early, select <strong>END PLAN</strong>.
      </Content>
    </DarkAlert>
  );
};

interface ExtendedHomePersonaliseProps extends PageProps<Queries.GetNewHomepageDataQuery> {
  myScheduledWorkouts?: ScheduledWorkout[];
}

const HomePersonalise: FC<ExtendedHomePersonaliseProps> = ({ data, myScheduledWorkouts }) => {
  const { tier, vimeoUserId, email, familyName, givenName, lmodSubscription } =
    useContext(userContext);

  useEffect(() => {
    if (tier === TIERS.BASE) {
      navigate('/explore');
    }
  }, [tier]);

  const layoutScripts = [
    {
      type: 'text/javascript',
      innerHTML: `var _cio = _cio || [];
              (function () {
                if (!document.getElementById('cio-tracker')) {
                var a, b, c;
                a = function (f) {
                  return function () {
                    _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
                  };
                };
                b = ['load', 'identify', 'sidentify', 'track', 'page'];
                for (c = 0; c < b.length; c++) {
                  _cio[b[c]] = a(b[c]);
                }
                var t = document.createElement('script'),
                  s = document.getElementsByTagName('script')[0];
                t.async = true;
                t.id = 'cio-tracker';
                t.setAttribute('data-site-id', '${process.env.GATSBY_RT_09_11_2023_CUSTOMERIO_SITE_ID}');
                t.setAttribute('data-use-in-app', 'true');
                t.src = 'https://assets.customer.io/assets/track.js';
                s.parentNode.insertBefore(t, s);
              }
              })();
              _cio.identify({
                id: '${vimeoUserId}',
                email: '${email}',
                first_name: '${givenName}',
                last_name: '${familyName}',
                plan_name: '${lmodSubscription?.product?.name}'
              });
            `,
    },
  ];

  return (
    <Layout
      scripts={layoutScripts}
      metaData={{
        title: data.prismicNewWebHomepage?.data?.meta_title,
        description: data.prismicNewWebHomepage?.data?.meta_description,
      }}
    >
      <Container>
        <HomepageWrapper>
          <CustomAlert />
          <SliceZone
            slices={data.prismicNewWebHomepage?.data?.body}
            listPrograms={data?.lesMillsPlusBackend?.listPrograms?.items}
            myScheduledWorkouts={myScheduledWorkouts}
            pageName={PageName.HOME}
          />
        </HomepageWrapper>
      </Container>
    </Layout>
  );
};

export default withPrismicPreview(
  withPrivateRoute(HomePersonalise as unknown as FC<Record<string, unknown>>)
);
