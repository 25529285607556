/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import BillingHistoryTable from './BillingHistoryTable';
import useGetInvoices from '../../hooks/queries/useGetInvoices';
import SubscriptionManagementLayout from '../common/subscriptionManagementLayout';
import Alert from '../common/alert/Alert';
import TableLoader from './TableLoader';
import { Note } from './styles';
import { getInvoiceStartDate } from './utils';
import ROUTES from '../../../../../src/constants/routes';
import { addErrorInDatadogRum } from '../../utils/utilities';

interface Props {
  pageData: Queries.PrismicBillingHistoryPageData;
}

const BillingHistory = ({ pageData }: Props) => {
  const [errorMessage, setErrorMessage] = useState('');

  const { data, loading } = useGetInvoices({
    variables: {
      input: {
        status: 'paid',
        start_date: getInvoiceStartDate(),
      },
    },
    onError: (err) => {
      setErrorMessage(pageData.error_on_list_invoice || '');
      addErrorInDatadogRum(err);
    },
  });

  const { title, note, no_invoice_message } = pageData || {};

  const onFreeTrial =
    data?.getInvoices?.length === 1 && data?.getInvoices[0].total_amount === '0.0';

  return (
    <SubscriptionManagementLayout
      title={title}
      backLink={{ handleClick: () => navigate(ROUTES().ACCOUNT) }}
      omitFooter
      theme="white"
      metaData={{
        title: 'Billing History',
      }}
    >
      {errorMessage && <Alert severity="error" content={errorMessage} />}
      {loading && <TableLoader numOfRows={5} />}
      {onFreeTrial && <p>{no_invoice_message}</p>}
      {data?.getInvoices && !onFreeTrial && (
        <BillingHistoryTable pageData={pageData} invoices={data.getInvoices} />
      )}
      <Note>
        <RichText render={note.richText} key={note.text} />
      </Note>
    </SubscriptionManagementLayout>
  );
};

export default BillingHistory;
