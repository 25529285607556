import styled from 'styled-components';
import { breakpoint, COLORS } from '@themes';

export const AlbumsCarouselSliceWrapper = styled.section`
  margin: 48px 0;
`;

export const TitleWrapper = styled.div`
  margin: 0 16px 24px;

  ${breakpoint('sm')`
    margin: 0 32px ;
  `}

  ${breakpoint('lg')`
    margin: 0 80px;
  `}
`;

export const Title = styled.h2`
  font-family: 'Inter SemiBold';
  font-size: 24px;
  line-height: 29px;
  color: ${(props) => props.theme.colors.white};
  margin: 0 0 8px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.lightishGrey};
  margin: 0;
`;

export const ContentWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 48px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 24px 0px;

  ${breakpoint('sm')`
    margin: 0 32px 24px 0px; 
  `}

  ${breakpoint('lg')`
    margin: 0 80px 24px 0px;
  `}
`;
export const ViewMoreButton = styled.button`
  font-family: 'Inter SemiBold';
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.lightGreen};
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;

  .videoCard {
    height: 100%;
  }

  & .swiper--wrapper {
    height: 100%;
    padding-left: 16px;
    padding-right: 0;

    ${breakpoint('sm')`
      padding-left: 32px;
      padding-right: 0;
    `}

    ${breakpoint('lg')`
      padding-left: 80px;
      padding-right: 0;
    `}
  }

  & .swiper {
    height: 100%;
    padding-left: 0px;
    padding-right: ${(props) => (props.noRightPadding ? 0 : '16px')};

    ${breakpoint('sm')`
      padding-right: ${(props) => (props.noRightPadding ? 0 : '32px')};
    `}

    ${breakpoint('lg')`
      padding-right: ${(props) => (props.noRightPadding ? 0 : '80px')};
    `}
  }

  & .home--carousel--wrapper {
    & .swiper--wrapper {
      height: 100%;
      padding-left: 16px;
      padding-right: 16px;

      ${breakpoint('sm')`
        padding-left: 32px;
        padding-right: 32px;
      `}

      ${breakpoint('lg')`
        padding-left: 80px;
        padding-right: 80px;
      `}
    }
  }
`;
