import React, { FC, useState, useRef, useEffect } from 'react';
import { AlbumCard } from '@lesmills-international/components';
import { contentClickTracking, linkClickTracking } from '@src/utils';
import { Event } from '@src/type';
import { useWindowSize } from '@hooks';
import Carousel from '@components/atoms/carousel/Carousel';
import { getSlideSpace, getSlidesPerView, getSlidesPerGroup } from '@services';
import { Link } from 'gatsby';

import {
  TitleWrapper,
  Title,
  Subtitle,
  CarouselWrapper,
  ContentWrapper,
  ViewMoreButton,
  HeaderWrapper,
} from './styles';

import {
  Early_Tenure_Home_PageBodyArticle_Carousel,
  Early_Tenure_Home_PageBodyArticle_CarouselFields,
} from '../../../generated/prismicGraphql';

interface ArticleCarouselContent extends Early_Tenure_Home_PageBodyArticle_Carousel {
  items: Early_Tenure_Home_PageBodyArticle_CarouselFields[];
}

interface Props {
  content: ArticleCarouselContent;
}

const EarlytenureWorkoutCarousel: FC<Props> = ({ content }) => {
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState(3);

  const [carouselSlidesPerGroup, setCarouselSlidesPerGroup] = useState(1);
  const [carouselSlidesSpace, setCarouselSlidesSpace] = useState(8);
  const [workoutCards, setWorkoutCards] = useState<JSX.Element[]>([]);

  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();

  useEffect(() => {
    const setArticles = async () => {
      if (carouselRef && carouselRef.current) {
        const carouselWrapperWidth = carouselRef.current.clientWidth;
        const slidesPerView = getSlidesPerView(carouselWrapperWidth);
        const slidesPerGroup = getSlidesPerGroup(carouselWrapperWidth);
        const slideSpace = getSlideSpace(carouselWrapperWidth);

        const articleCardArray = content.items.map((article, index) => (
          <AlbumCard
            key={index}
            label={article.type}
            title={article.title}
            backgroundImg={article.background_image?.url}
            link={article.link}
            aspectRatio="landscape"
            onClick={() => {
              contentClickTracking({
                contentListTitle: content?.primary?.title,
                contentListIndex: index + 1,
                contentTitle: article.title,
                contentType: 'article',
              });
            }}
          />
        ));

        if (articleCardArray.length < slidesPerView) {
          for (let i = 0; i < slidesPerView - articleCardArray.length; i++) {
            articleCardArray.push(<div key={`empty-${i}`} />);
          }
        }

        setCarouselSlidesPerView(slidesPerView);
        setCarouselSlidesPerGroup(slidesPerGroup);
        setCarouselSlidesSpace(slideSpace);
        setWorkoutCards(articleCardArray);
      }
    };

    setArticles();
  }, [windowInnerWidth, content.items, content?.primary?.title]);

  return (
    <ContentWrapper>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>{content?.primary?.title}</Title>
          <Subtitle>{content?.primary?.subtitle}</Subtitle>
        </TitleWrapper>
        <Link to={content?.primary?.view_more_url || ''}>
          <ViewMoreButton
            onClick={() => {
              linkClickTracking({
                eventName: Event.CLICK_TO_EXPLORE,
                link: content?.primary?.view_more_text,
                contentListTitle: content?.primary?.title,
              });
            }}
          >
            {content?.primary?.view_more_text}
          </ViewMoreButton>
        </Link>
      </HeaderWrapper>
      <CarouselWrapper ref={carouselRef} noRightPadding>
        <Carousel
          items={workoutCards}
          slidesPerView={carouselSlidesPerView}
          slidesPerGroup={carouselSlidesPerGroup}
          spaceBetween={carouselSlidesSpace}
          className="home--carousel--wrapper"
          alwaysShowArrowButton
        />
      </CarouselWrapper>
    </ContentWrapper>
  );
};

export default EarlytenureWorkoutCarousel;
