import styled from 'styled-components';
import { breakpoint } from '@themes';

export const PlansCarouselSliceWrapper = styled.section`
  margin: 48px 0;
`;

export const TitleWrapper = styled.div`
  margin: 0 16px 24px;

  ${breakpoint('sm')`
    margin: 0 32px 24px;
  `}

  ${breakpoint('lg')`
    margin: 0 80px 24px;
  `}
`;

export const Title = styled.h2`
  font-family: 'Inter SemiBold';
  font-size: 24px;
  line-height: 29px;
  color: ${(props) => props.theme.colors.white};
  margin: 0 0 8px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.lightishGrey};
  margin: 0;
`;

export const CarouselWrapper = styled.div<{ noRightPadding?: boolean }>`
  .home--carousel--wrapper {
    padding: 0 16px;
    margin: 0;
    ${breakpoint('sm')`
      padding: 0 32px;
    `}

    ${breakpoint('lg')`
      padding: 0 80px;
    `}

    ${({ noRightPadding }) =>
      noRightPadding &&
      `
      .swiper-wrapper {
        padding-right: 80px;
      }
    `}
  }
`;

export const SlicePlaceholderWrapper = styled.div`
  margin: 0 16px;
  height: 280px;

  ${breakpoint('sm')`
    margin: 0 32px;
  `}

  ${breakpoint('lg')`
    margin: 0 80px;
  `}
`;
