/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@lesmills-international/components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { PageProps, graphql } from 'gatsby';
import { ReactComponent as WarningIcon } from '@assets/icons/warning.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-green.svg';
import SubscriptionManagementLayout from '../../common/subscriptionManagementLayout';
import { logEvent } from '../../../../../../src/utils/amplitude';
import ErrorMessage from '../errorMessage';
import PlanCard from '../../signup/selectPlan/PlanCardTiering';
import useGetPromoInfo from '../hooks/useGetPromoInfo';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import useGetProduct from '../hooks/useGetProduct';
import { normalizeTieringPriceData } from '../../../utils/normalizePriceData';
import {
  generateTermsAndConditionText,
  getCurrentPaymentFrequency,
  handleMyAccountPageNavigation,
  handleRefresh,
  mapPricingData,
  mapPrismicErrorDescription,
  mapPromoError,
  mapSuccessMessageItem,
  formatDate,
  generatePricingCardDescription,
} from '../utils';
import { TermsCondition, Container, AccountPageLink } from '../styles';
import ProcessingMsg from '../../common/ProcessingMsg/ProcessingMsg';
import SuccessMessage from '../successMessage';
import { SuccessType, IsFrom, SubscriptionState } from '../type';
import redeemPromoCode from '../../../graphql/redeemPromoCode';
import { GENERIC_ERROR, PromoErrorType } from '../constants';
import { addErrorInDatadogRum, priceFormat } from '../../../utils/utilities';
import { getBillingPeriod } from '../../../utils/currencyPromotions';
import { TAX_INCLUDED_CURRENCIES } from '../../../constants/currency';
import useCancelSubscription from '../../../hooks/mutation/useCancelSubscription';

interface Props extends Pick<PageProps, 'location'> {
  pageData: Queries.GetOfferRedemptionQuery;
}

const OfferRedemptionTiering = ({ pageData, location }: Props) => {
  const {
    prismicOfferRedemptionPage,
    prismicLesMillsPlusTieringLandingPage,
    prismicLesMillsPlusCommonLandingPage,
  } = pageData || {};

  const [successType, setSuccessType] = useState<SuccessType | null>(null);
  const [error, setError] = useState<PromoErrorType | undefined>(undefined);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string | null>(null);

  const urlParams = new URLSearchParams(location.search);
  const promoCode = urlParams.get('promoCode') || '';
  const isFrom = (urlParams.get('isFrom') || IsFrom.HOME_PAGE) as IsFrom;

  const { addressCountry, lmodSubscription: userProductInfo } = useContext(userContext) || {};

  const countryCode = addressCountry?.toLowerCase();

  const planCardPrismicData = normalizeTieringPriceData(
    prismicLesMillsPlusTieringLandingPage,
    prismicLesMillsPlusCommonLandingPage
  );

  const [handleRedeemOffer, { loading: redemptionLoading }] = useMutation(redeemPromoCode, {
    onCompleted: () => {
      setSuccessType(SuccessType.OFFER_REDEMPTION_SUCCESS);
    },
    onError: (err) => {
      setError(GENERIC_ERROR);
      addErrorInDatadogRum(err);
    },
  });

  const [handleCancelSubscription, { loading: cancelSubscriptionLoading }] = useCancelSubscription({
    onCompleted: (response) => {
      const data = response?.cancelSubscription;
      setSuccessType(SuccessType.CANCELLATION_SUCCESS);
      setSubscriptionEndDate(formatDate(data?.current_period_ends_at!));
      logEvent('cancel_subscription', {
        current_subscription_tier: data?.product?.tier?.internal_tier_id,
        current_subscription_term: getBillingPeriod(data?.product?.product_price_point?.interval, {
          monthly: monthly_interval,
          quarterly: trimonthly_interval,
          yearly: annual_interval,
        }),
        current_subscription_start_date: data?.current_period_started_at,
        current_subscription_end_date: data?.current_period_ends_at,
      });
    },
    onError: (err) => {
      setError(GENERIC_ERROR);
      addErrorInDatadogRum(err);
    },
  });

  const {
    title,
    logo,
    back_to_home_button_text,
    back_to_my_account_button_text,
    offer_banner_text,
    term_and_condition_renewal,
    term_and_condition_change_product,
    upgrade_button_text,
    loading_process_text,
    loading_text,
    cancel_button_text,
    success_primary_button_text,
    success_secondary_button_text,
    offer_redemption_success_description,
    offer_redemption_success_title,
    cancellation_success_description,
    cancellation_success_title,
    cancel_subscription_button_text,
    try_again_button_text,
    account_page_link_text,
    cancel_subscription_button_loading_text,
    trimonthly_interval,
    monthly_interval,
    annual_interval,
    pricing_card_description,
    tax_label,
  } = prismicOfferRedemptionPage?.data || {};

  const formattedCancellationSuccessDescription = cancellation_success_description?.replace(
    '{end_date}',
    subscriptionEndDate || ''
  );

  const successMessageProps = mapSuccessMessageItem(successType, {
    defualtTitle: offer_redemption_success_title,
    defaultDescription: offer_redemption_success_description,
    cancellationDescription: (
      <span>
        {formattedCancellationSuccessDescription}{' '}
        <AccountPageLink href={`${window.location.origin}/account`} rel="noreferrer">
          {account_page_link_text}
        </AccountPageLink>
        .
      </span>
    ),
    cancellationTitle: cancellation_success_title,
    secondaryButtonText: success_secondary_button_text,
    primaryButtonText: success_primary_button_text,
  });

  const {
    promoInformation: promotionInformation,
    loading: getPromoInfoLoading,
    promoError,
  } = useGetPromoInfo(promoCode);

  const {
    productInfo: productInformation,
    loading: getProductLoading,
    productError,
  } = useGetProduct({
    subdomain: userProductInfo?.subdomain,
    countryCode,
    productHandle: promotionInformation?.productHandle,
  });

  useEffect(() => {
    setError(promoError || productError);
  }, [promoError, productError]);

  const isLoading = getPromoInfoLoading || getProductLoading;
  const pricingData = useMemo(
    () =>
      mapPricingData({
        productInformation,
        promotionInformation,
        countryCode,
      }),
    [productInformation, promotionInformation, countryCode]
  );

  const billingPeriod = getBillingPeriod(pricingData.interval, {
    monthly: monthly_interval,
    quarterly: trimonthly_interval,
    yearly: annual_interval,
  });

  const includeTaxLabel =
    !productInformation?.product_price_point?.tax_included &&
    pricingData?.currency === TAX_INCLUDED_CURRENCIES.CAD;

  const priceFormatData = {
    cents: pricingData?.regularPrice,
    currency: pricingData?.currency,
    addTax: false,
    toFixedNumber: false,
  };

  const tncPrice = priceFormat(priceFormatData);

  const tncPriceWithTaxLabel = priceFormat({
    ...priceFormatData,
    addTax: includeTaxLabel,
    taxLabel: tax_label || '',
  });

  const pricingCardDescriptionPrice = priceFormat({
    ...priceFormatData,
    cents: pricingData?.price,
  });

  const tncText = generateTermsAndConditionText({
    billingPeriod,
    amount: includeTaxLabel ? tncPriceWithTaxLabel : tncPrice,
    description:
      promotionInformation?.type === 'RENEWAL'
        ? term_and_condition_renewal
        : term_and_condition_change_product,
  });

  const pricingCardDescription = generatePricingCardDescription({
    amount: pricingCardDescriptionPrice,
    description: pricing_card_description,
  });

  const paymentFrequency = useMemo(
    () => getCurrentPaymentFrequency(planCardPrismicData, pricingData?.interval),
    [planCardPrismicData, pricingData?.interval]
  );

  const isEligible = !error;
  const errorCode = promoError || productError;

  const prismicPromoErrorDescription = mapPrismicErrorDescription(
    mapPromoError(errorCode),
    prismicOfferRedemptionPage
  );

  const lesmillsLogo = () =>
    logo?.gatsbyImageData ? (
      <GatsbyImage
        image={logo.gatsbyImageData as unknown as IGatsbyImageData}
        alt={logo.alt || ''}
      />
    ) : null;

  const secondaryButtonProps =
    isFrom === IsFrom.CANCELLATION
      ? {
          label: cancel_subscription_button_text,
          action: () => handleCancelSubscription(),
        }
      : {
          label: cancel_button_text,
          action: () => handleMyAccountPageNavigation(isFrom === IsFrom.MY_ACCOUNT_PAGE),
        };

  const renderIcon = () => {
    let icon = isEligible ? lesmillsLogo() : <WarningIcon />;

    if (successType && successMessageProps) {
      icon = successMessageProps.hasIcon ? <SuccessIcon /> : null;
    }

    return icon;
  };

  const isFromMyAccount = isFrom === IsFrom.CANCELLATION || isFrom === IsFrom.MY_ACCOUNT_PAGE;
  const isCancellationButtonNotVisible = () =>
    isFrom === IsFrom.CANCELLATION &&
    (userProductInfo?.state === SubscriptionState.CANCELED ||
      userProductInfo?.state === SubscriptionState.ON_DELAYED_CANCELLATION);

  const renderMain = () => {
    let mainComponent = isEligible ? (
      <>
        <PlanCard
          plan={pricingData}
          key={pricingData.name}
          selectedPlan={pricingData}
          currentFrequency={paymentFrequency}
          isHidden={false}
          pricingData={planCardPrismicData}
          countryCode={countryCode}
          noTrialDisplay
          collapse
          handleSelectPlan={() => null}
          currentProductHandle={userProductInfo?.product_handle}
          customizePromoMessage={offer_banner_text}
          customPromotionDescription={pricingCardDescription}
        />

        <TermsCondition>
          <p>{tncText}</p>
        </TermsCondition>
        <Button
          style={{ width: '100%', maxWidth: '375px' }}
          loading={redemptionLoading}
          label={upgrade_button_text}
          onClick={() => handleRedeemOffer({ variables: { promoCode } })}
          ctaButton
          disabled={cancelSubscriptionLoading}
        />
        {!isCancellationButtonNotVisible() && (
          <Button
            label={
              cancelSubscriptionLoading
                ? cancel_subscription_button_loading_text
                : secondaryButtonProps.label
            }
            onClick={secondaryButtonProps.action}
            variant="text"
            disabled={cancelSubscriptionLoading || redemptionLoading}
          />
        )}
      </>
    ) : (
      <ErrorMessage
        description={prismicPromoErrorDescription}
        primaryButtonText={
          isFromMyAccount ? back_to_my_account_button_text : back_to_home_button_text
        }
        primaryButtonOnClick={() => handleMyAccountPageNavigation(isFromMyAccount)}
        secondaryButtonText={try_again_button_text}
        secondaryButtonOnClick={handleRefresh}
      />
    );

    if (successType && successMessageProps) {
      mainComponent = (
        <SuccessMessage
          title={successMessageProps.title}
          description={successMessageProps.description}
          primaryButton={successMessageProps.primaryButton}
          secondaryButton={successMessageProps.secondaryButton}
        />
      );
    }

    return mainComponent;
  };

  return (
    <>
      {isLoading ? (
        <ProcessingMsg
          loadingMsg={loading_text || ''}
          loadingProcessMsg={loading_process_text || ''}
        />
      ) : (
        <SubscriptionManagementLayout
          hideHeader
          title={isEligible && !successType ? title : ''}
          size="medium"
          alignCenter
          icon={renderIcon()}
          metaData={{
            title: 'Offer Redemption',
          }}
        >
          <Container>{renderMain()}</Container>
        </SubscriptionManagementLayout>
      )}
    </>
  );
};

export default OfferRedemptionTiering;

export const PricingSectionCommonQuery = graphql`
  fragment PricingSectionOfferRedemption on PrismicLesMillsPlusCommonLandingPageDataBodyPricing {
    slice_type
    primary {
      annually_text
      billed_text
      cancellation_text
      change_plan
      day
      days
      free_trial_text
      month
      monthly_text
      months
      no_trial_text
      pricing_cta_button
      pricing_cta_label
      pricing_disclaimer
      pricing_subtitle
      pricing_title
      winback_pricing_title
      no_free_trial_text
      three_monthly_text
      best_value
      year
      plan_monthly_disclaimer
      plan_trimonthly_disclaimer
      plan_annually_disclaimer
      billed_text
      first_payment
      next
      oneoff_first_payment
      oneoff_monthly_billed
      oneoff_trial_text
    }
  }
`;

export const TieringPricingSectionCommonQuery = graphql`
  fragment TieringPricingSectionCommonOfferRedemption on PrismicLesMillsPlusCommonLandingPageDataBodyTieringPricing {
    slice_type
    primary {
      annually_text
      three_monthly_text
      billed_text
      cancellation_text
      change_plan
      day
      days
      free_trial_text
      month
      monthly_text
      months
      no_trial_text
      pricing_cta_button
      pricing_cta_label
      pricing_disclaimer
      pricing_subtitle
      pricing_title
      best_value
      year
      plan_monthly_disclaimer
      plan_trimonthly_disclaimer
      plan_annually_disclaimer
      billed_text
      base_tier_title
      premium_tier_title
      base_tier_description
      premium_tier_description
      base_tier_monthly_promo
      base_tier_trimonthly_promo
      base_tier_annually_promo
      premium_tier_monthly_promo
      premium_tier_trimonthly_promo
      premium_tier_annually_promo
      switch_default_value
      monthly_badge
      trimonthly_badge
      annually_badge
      badge_text
      best_value_option
    }
    items {
      tier_type
      benefit_item_text
      benefit_item_icon
      benefit_item_type
      children_content
      tooltip_children_content
      children_title
    }
  }
`;

export const TieringPricingSectionTieringLandingQuery = graphql`
  fragment TieringPricingSectionTieringOfferRedemption on PrismicLesMillsPlusTieringLandingPageDataBodyTieringPricing {
    slice_type
    primary {
      annually_text
      three_monthly_text
      billed_text
      cancellation_text
      change_plan
      day
      days
      free_trial_text
      month
      monthly_text
      months
      no_trial_text
      pricing_cta_button
      pricing_cta_label
      pricing_disclaimer
      pricing_subtitle
      pricing_title
      best_value
      best_value_option
      year
      plan_monthly_disclaimer
      plan_trimonthly_disclaimer
      plan_annually_disclaimer
      billed_text
      base_tier_title
      premium_tier_title
      base_tier_description
      premium_tier_description
      base_tier_monthly_promo
      base_tier_trimonthly_promo
      base_tier_annually_promo
      premium_tier_monthly_promo
      premium_tier_trimonthly_promo
      premium_tier_annually_promo
    }
    items {
      benefit_item_icon
      benefit_item_text
      benefit_item_type
      children_content
      tooltip_children_content
      children_title
      tier_type
    }
  }
`;
