import React, { FC, useContext } from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import { withPrivateRoute } from '@components/molecules';
import { userContext } from '@context';
import { breakpoint } from '@themes';
import SliceZone from '@components/sliceZone';
import { useSurveyAnswers } from '@hooks';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SlicePlaceholder } from '@components/atoms';
import {
  Early_Tenure_Home_Page,
  Early_Tenure_Home_PageGreetings,
  Maybe,
} from '../../../generated/prismicGraphql';
import { SURVEY_QUESTION_IDS } from '../../../constants';
import { ScheduledWorkout } from '../../../hooks/useMyScheduledWorkouts';

const HomepageWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
`;
const Container = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  min-height: calc(100vh - 64px);
`;

const Title = styled.h4`
  color: ${(props) => props.theme.colors.white};
  font-size: 35px;
  line-height: 42px;
  font-family: 'Inter SemiBold';
  margin: 56px 0 24px;
  padding: 0 1rem 0px 1rem;

  ${breakpoint('sm')`
    font-size: 35px;
    line-height: 50px;
    padding: 0 2rem;
  `}

  ${breakpoint('md')`
    font-size: 35px;
    line-height: 42px;
    padding: 0 2rem;
  `}
  ${breakpoint('lg')`
    font-size: 35px;
    line-height: 42px;
    padding: 0 5rem;
  `}
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface HomeEarlyTenureProps {
  prismicEarlyTenureHomePage: {
    data: Early_Tenure_Home_Page;
  };
}

interface ExtendedHomeEarlyTenureProps extends PageProps<HomeEarlyTenureProps> {
  myScheduledWorkouts?: ScheduledWorkout[];
  isLoading?: boolean;
}

interface SurveyUserAnswer {
  questionIntent: string;
  questionId: string;
  answers?: Maybe<
    Maybe<{
      selected?: Maybe<boolean>;
      displayText?: Maybe<string>;
    }>[]
  >;
}

const extractSurveyData = (surveyAnswers: SurveyUserAnswer[] | undefined) => {
  const fitnessLevelAnswers = surveyAnswers?.find(
    (answers) => answers.questionIntent === SURVEY_QUESTION_IDS.FITNESS_LEVEL
  );

  const categoryAnswers = surveyAnswers?.find(
    (answers) => answers.questionIntent === SURVEY_QUESTION_IDS.CATEGORY_FAVORITES
  );

  const fitnessLevel =
    fitnessLevelAnswers?.answers?.find((answer) => answer?.selected)?.displayText || undefined;

  const categoriesSelected = categoryAnswers?.answers
    ?.filter((answer): answer is NonNullable<typeof answer> => Boolean(answer?.selected))
    ?.map((answer) => answer.displayText)
    ?.filter((text): text is string => Boolean(text));

  return {
    fitnessLevel,
    categoriesSelected: categoriesSelected || [],
  };
};

const getGreeting = (greetings: Early_Tenure_Home_PageGreetings[]) => {
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  const currentTime = currentHour * 100 + currentMinute;
  return greetings.find((greeting) => {
    const startTime = greeting?.start_time ? parseInt(greeting.start_time.replace(':', ''), 10) : 0;
    const endTime = greeting?.end_time ? parseInt(greeting.end_time.replace(':', ''), 10) : 0;
    return currentTime >= startTime && currentTime <= endTime;
  });
};

const HomeEarlyTenure = ({
  data,
  myScheduledWorkouts,
  isLoading,
}: ExtendedHomeEarlyTenureProps) => {
  const { data: content } = data.prismicEarlyTenureHomePage;

  const { mySurveyAnswers, isLoading: surveyLoading } = useSurveyAnswers();

  const { givenName } = useContext(userContext);

  const { fitnessLevel, categoriesSelected } = extractSurveyData(mySurveyAnswers);

  const greeting = getGreeting(content.greetings || []);

  const personalizedContent = {
    ...content,
    body: content?.body?.map((slice) => ({
      ...slice,
      primary: {
        ...slice.primary,
        fitnessLevel,
        categoriesSelected,
      },
    })),
  };

  const hasActivePlan = myScheduledWorkouts?.some(
    (workout) => workout.plan?.id && workout.plan?.name
  );

  const hasScheduledWorkouts = myScheduledWorkouts?.some(
    (workout) => !workout.plan || workout.plan === null
  );

  personalizedContent.body = personalizedContent?.body?.filter((slice) => {
    // Scenario 1: No plan, no scheduled workouts
    // Show plans_carousel, hide my_scheduled_workouts
    if (!hasActivePlan && !hasScheduledWorkouts) {
      return slice.slice_type !== 'my_scheduled_workouts';
    }

    // Scenario 2: No plan, but has scheduled workouts
    // Show both
    if (!hasActivePlan && hasScheduledWorkouts) {
      return true;
    }

    // Scenario 3: Yes plan, no scheduled workouts
    // Show my_scheduled_workouts, hide plans_carousel
    if (hasActivePlan && !hasScheduledWorkouts) {
      return slice.slice_type !== 'plans_carousel';
    }

    return true;
  });

  return (
    <Container>
      <HomepageWrapper>
        <Title>
          {greeting?.greeting_text}, {givenName}
        </Title>
        {isLoading || surveyLoading ? (
          <LoadingWrapper>
            <SlicePlaceholder height="400px" width="80%" />
          </LoadingWrapper>
        ) : (
          <SliceZone slices={personalizedContent?.body} myScheduledWorkouts={myScheduledWorkouts} />
        )}
      </HomepageWrapper>
    </Container>
  );
};

export default withPrismicPreview(
  withPrivateRoute(HomeEarlyTenure as unknown as FC<Record<string, unknown>>)
);
