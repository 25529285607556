import styled from 'styled-components';
import { breakpoint } from '@themes';

const InterestFilterWrapper = styled.section`
  margin: 48px 0;
`;

const TitleWrapper = styled.div`
  margin: 0 16px 0px;

  ${breakpoint('sm')`
    margin: 0 32px 0px;
  `}

  ${breakpoint('lg')`
    margin: 0 80px 0px;
  `}
`;

const Title = styled.h2`
  font-family: 'Inter SemiBold';
  font-size: 24px;
  line-height: 29px;
  color: ${(props) => props.theme.colors.white};
  margin: 0 0 8px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.lightishGrey};
  margin: 0;
`;

const ContentWrapper = styled.div`
  padding: 0 16px;

  ${breakpoint('sm')`
    padding: 0 32px;
  `}

  ${breakpoint('lg')`
    padding: 0 80px;
  `}
`;

const FilterWrapper = styled.div`
  & .item--wrapper {
    margin-bottom: 16px;
  }
`;

const ProgramCard = styled.div<{ isSmallScreen: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => (props.isSmallScreen ? '24px' : '0')};

  ${breakpoint('lg')`
    padding-bottom: 0;
  `}
`;

const VideoCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const OffsetWrapper = styled.div<{ isFirst: boolean; isLast: boolean; totalElements: number }>`
  margin-top: ${(props) => (props.isFirst ? '-12px' : '0')};
  margin-bottom: ${(props) => {
    if (props.totalElements === 3 && props.isLast) {
      return '-28px';
    }
    if (props.totalElements === 4 && props.isLast) {
      return '-12px';
    }
    return '0';
  }};
`;

const FilterWrapToNextLine = styled.div`
  gap: 8px;

  ${breakpoint('lg')`
    flex-wrap: wrap;

    & .item--wrapper {
      flex-wrap: wrap;
    }
  `}
`;
export {
  InterestFilterWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  ContentWrapper,
  FilterWrapper,
  ProgramCard,
  OffsetWrapper,
  VideoCardContainer,
  FilterWrapToNextLine,
};
