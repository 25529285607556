import React from 'react';
import { RichText as RichTextParser } from 'prismic-reactjs';
import HtmlSerializer from './htmlSerializer';

const RichText = ({ text, style, onClick }) => (
  <RichTextParser
    render={text?.richText}
    htmlSerializer={(type, element, content, children, key) =>
      HtmlSerializer(type, element, content, children, key, style, onClick)
    }
  />
);

export default RichText;
