import React, { useEffect } from 'react';
import { Layout, LegalCountryNavigation, withPublicRoute } from '@components/molecules';
import { LegalRichText } from '@components/atoms';
import { graphql } from 'gatsby';
import { Theme } from '@components/molecules/layout';
import { optOutAmplitude, optInAmplitude } from '@src/utils';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import {
  Cookie_Policy_Page,
  Cookie_Policy_PageBodyCookie_Policy,
} from '../../generated/prismicGraphql';
import { COUNTRY_CODES, replaceSpaceInString } from '../../utils/legalPageUtils';
import {
  SectionContainer,
  TitleContainer,
  ContentContainer,
  LegalHeader,
  LegalTitle,
  StyledHr,
} from './legalPageStyles';

type PrismicRichTextType = {
  raw: any[];
  text: string;
};

interface CookiePolicyData {
  cookie_policy_description: PrismicRichTextType;
  cookie_policy_hyperlink_title: PrismicRichTextType;
  cookie_policy_title: PrismicRichTextType;
  id: PrismicRichTextType;
}

interface CookiePolicyBodyExtended extends Cookie_Policy_PageBodyCookie_Policy {
  items: CookiePolicyData[];
}

interface NavigationItem {
  text: string;
  url: string;
}

interface CookiePolicyPageProps {
  data: {
    prismicCookiePolicyPage: {
      data: Cookie_Policy_Page;
    };
  };
}

const CookiePolicyPage: React.FC<CookiePolicyPageProps> = ({ data }) => {
  const sliceData: Cookie_Policy_Page = data.prismicCookiePolicyPage.data;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, body, page_title } = sliceData;

  useEffect(() => {
    const scriptTag = document.createElement('script');
    const mainWrapper = document.getElementById('cookieConsentWrapper');

    scriptTag.src = 'https://consent.cookiebot.com/aadef9b5-2bd2-4822-ad7f-1378d076b868/cd.js';
    scriptTag.id = 'CookieDeclaration';
    scriptTag.type = 'text/javascript';

    mainWrapper?.appendChild(scriptTag);
    return () => {
      mainWrapper?.removeChild(scriptTag);
    };
  }, []);

  useEffect(() => {
    const handleCookieConsentChange = () => {
      if (typeof window !== 'undefined' && (window as any).Cookiebot) {
        if ((window as any).Cookiebot.consent.statistics) {
          optInAmplitude();
        } else {
          optOutAmplitude();
        }
      }
    };

    window.addEventListener('CookiebotOnDecline', handleCookieConsentChange);
    window.addEventListener('CookiebotOnAccept', handleCookieConsentChange);

    return () => {
      window.removeEventListener('CookiebotOnDecline', handleCookieConsentChange);
      window.removeEventListener('CookiebotOnAccept', handleCookieConsentChange);
    };
  }, []);

  if (!body) {
    return null;
  }

  const blocksForEachCountry: CookiePolicyBodyExtended = body[0] as CookiePolicyBodyExtended;

  const countryNavItems: NavigationItem[] = blocksForEachCountry?.items.map(
    (countryBlock: CookiePolicyData) => ({
      text: countryBlock.cookie_policy_hyperlink_title.text,
      url: `#${replaceSpaceInString(countryBlock.id.text || '', '_')}`,
    })
  );

  return (
    <Layout theme={Theme.White} metaData={{ title: page_title?.text }}>
      <SectionContainer>
        <SectionContainer id="cookieConsentWrapper">
          <LegalHeader>MANAGE YOUR COOKIES</LegalHeader>
        </SectionContainer>

        <SectionContainer>
          <TitleContainer>
            <LegalHeader>{title.text}</LegalHeader>
          </TitleContainer>
        </SectionContainer>

        <SectionContainer>
          <LegalCountryNavigation navs={countryNavItems} />
        </SectionContainer>

        <SectionContainer>
          {blocksForEachCountry?.items.map((countryBlock: CookiePolicyData, index: number) => {
            const countryCode = countryBlock.id.text || '';
            const isArabic = countryCode === COUNTRY_CODES.ARABIC;

            return (
              <ContentContainer
                key={index}
                isArabic={isArabic}
                id={replaceSpaceInString(countryCode, '_')}
              >
                <LegalTitle isArabic={isArabic}>{countryBlock.cookie_policy_title.text}</LegalTitle>

                <LegalRichText text={countryBlock.cookie_policy_description.raw} />

                <StyledHr />
              </ContentContainer>
            );
          })}
        </SectionContainer>
      </SectionContainer>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    prismicCookiePolicyPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicCookiePolicyPageDataBodyCookiePolicy {
            id
            items {
              cookie_policy_description {
                raw
                text
              }
              cookie_policy_hyperlink_title {
                raw
                text
              }
              cookie_policy_title {
                raw
                text
              }
              id {
                text
              }
            }
          }
        }
        title {
          raw
          text
        }
        page_title {
          text
        }
      }
    }
  }
`;

export default withPrismicPreview(withPublicRoute(CookiePolicyPage));
