/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { ListItem, ListItemProps, Toggle } from '@lesmills-international/components';
import { Elements, HTMLSerializer, RichText } from 'prismic-reactjs';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { Button, EmailPreferenceDescription, EmailPreferenceInfo, SectionTitle } from './styles';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import useUpdateSubcomms from '../../hooks/mutation/useUpdateSubcomms';
import { addErrorInDatadogRum } from '../../utils/utilities';
import useUpdateSubCommsV2 from '../../hooks/mutation/useUpdateSubCommsV2';

interface Props {
  data: Pick<
    Queries.PrismicAccountOverviewPageData,
    | 'email_preferences_section_title'
    | 'email_preferences_title'
    | 'email_preferences_description'
    | 'email_preferences_error_message'
    | 'double_opt_in_message'
    | 'email_sent_message'
  >;
  locationState: any;
}

const EmailPreferencesSection = ({ data, locationState }: Props) => {
  const { subComms, subCommsConfirmed, refetchUser } = useContext(userContext);

  const { rtUpdateSubCommsMutationNew } = useFlags();

  const [isProcessing, setIsProcessing] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [handleUpdateSubcomms, { error }] = useUpdateSubcomms({
    onError: (err) => {
      addErrorInDatadogRum(err);
    },
  });

  const [handleUpdateSubCommsV2, { error: errorV2 }] = useUpdateSubCommsV2({
    onError: (err) => {
      addErrorInDatadogRum(err);
    },
  });

  const emailNotConfirmed = subComms && !subCommsConfirmed && !isProcessing && !emailSent;

  const handleOnChange = (checked: boolean, successCallback?: () => void) => {
    setIsProcessing(true);

    const onCompleted = async () => {
      refetchUser && (await refetchUser());
      setIsProcessing(false);
      successCallback && successCallback();
    };

    if (rtUpdateSubCommsMutationNew) {
      handleUpdateSubCommsV2({ variables: { value: checked }, onCompleted });
    } else {
      handleUpdateSubcomms({
        variables: {
          input: {
            subComms: checked,
          },
        },
        onCompleted,
      });
    }
  };

  const {
    email_preferences_section_title,
    email_preferences_title,
    email_preferences_description,
    email_preferences_error_message,
    email_sent_message,
    double_opt_in_message,
  } = data;

  const convertPrismicElementToButton: HTMLSerializer<ReactNode> = (...[type, , content, , ,]) => {
    if (type === Elements.strong) {
      return (
        <Button onClick={() => handleOnChange(true, () => setEmailSent(true))}>{content}</Button>
      );
    }
  };

  const items: ListItemProps = [
    {
      title: email_preferences_title,
      description: (
        <EmailPreferenceDescription>
          {emailNotConfirmed && (
            <EmailPreferenceInfo>
              <RichText
                render={double_opt_in_message?.richText}
                key={double_opt_in_message.text}
                htmlSerializer={convertPrismicElementToButton}
              />
            </EmailPreferenceInfo>
          )}

          {(error || errorV2) && (
            <div className="error" role="alert">
              {email_preferences_error_message}
            </div>
          )}
          {emailSent && (
            <div className="success" role="alert">
              {email_sent_message}
            </div>
          )}
          {email_preferences_description}
          <Toggle
            checked={subComms}
            onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
              handleOnChange(checked)
            }
            disabled={isProcessing || emailNotConfirmed}
            sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '20px' }}
          />
        </EmailPreferenceDescription>
      ),
    },
  ];

  useEffect(() => {
    if (locationState?.fromHeader) {
      refetchUser?.();
    }
  }, [locationState, refetchUser]);

  return (
    <div>
      <SectionTitle>{email_preferences_section_title}</SectionTitle>
      <ListItem items={items} />
    </div>
  );
};

export default EmailPreferencesSection;
