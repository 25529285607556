import * as React from 'react';
import { Elements } from 'prismic-richtext';

import { Link } from '@components/atoms';
import { linkResolver } from '../../../../../../src/services/linkResolver';
/**
 * Turns a prismic Rich Text object into serialized HTML
 */

const htmlSerializer = (type, element, content, children, key, style, onClick) => {
  switch (type) {
    // Add a class to paragraph elements
    case Elements.paragraph:
      return (
        <p key={key} className="font-inter-light text-16 leading-normal pb-4 lg:pb-6">
          {children}
        </p>
      );

    // Heading 2
    case Elements.heading2:
      return (
        <h2
          key={key}
          className="font-inter-semibold leading-none uppercase text-24 lg:text-32 py-2 lg:py-3"
        >
          {children}
        </h2>
      );

    // Add a class to hyperlinks
    case Elements.hyperlink:
      return (
        <Link
          className={style || 'text-green hover:underline'}
          to={element.data.url || linkResolver(element.data)}
          //  to={require('../../../services/linkResolver').linkResolver(element.data)}
          key={key}
          onClick={onClick}
        >
          {content}
        </Link>
      );
    case Elements.list:
      return (
        <ul key={key} className="list-disc list-inside space-y-2 lg:space-y-3">
          {children}
        </ul>
      );
    case Elements.oList:
      return (
        <ul key={key} className="list-decimal list-inside space-y-2 lg:space-y-3">
          {children}
        </ul>
      );

    // Return null to stick with the default behavior
    default:
      return null;
  }
};

export default htmlSerializer;
