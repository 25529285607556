/* eslint-disable @typescript-eslint/naming-convention */
import { Event, Params } from '../type/googleAnalytics';
import { Product } from '../type/product';
import { getConvertedPrice, getLocalStorage, getPlanPeriod, isBrowser } from './utilities';
import { PARAM_VALUE } from '../../packages/gatsby-theme-engagement/src/constants';
import { logEvent } from './amplitude';

type ProductType = Pick<
  Product,
  'name' | 'tier' | 'product_price_point' | 'product_handle' | 'currency'
>;

interface UserData {
  vimeoUserId: string;
  product: ProductType;
}

interface PageViewTracking {
  userData?: UserData | null;
  loginStatus: 'true' | 'false';
}

interface ContentClickParams {
  contentIndex: number;
  contentListTitle: string;
  contentListIndex: number;
  contentTitle: string;
  contentType: 'video' | 'article' | 'product' | 'other';
  contentTopic: string;
  contentLocation: string;
}

export const pushTracking = (data: any) => {
  if (!isBrowser && !window.dataLayer && !data) {
    return;
  }
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    ...data,
  });
};

export const pageViewTracking = ({ userData, loginStatus }: PageViewTracking) => {
  const { product_price_point, name, tier, currency } = userData?.product || {};

  pushTracking({
    event: Event.PAGE_VIEW,
    [Params.PLAN_NAME]: name,
    [Params.PLAN_TIER]: tier?.internal_tier_id,
    [Params.PLAN_BILLING_FREQ]:
      product_price_point &&
      getPlanPeriod(product_price_point?.interval, product_price_point?.interval_unit),
    [Params.CURRENCY]: currency,
    [Params.PLAN_PRICE]:
      product_price_point &&
      getConvertedPrice(product_price_point?.price_in_cents, currency)?.toString(),
    [Params.CUSTOMER_LOGGED_IN_STATUS]: loginStatus,
    [Params.VIMEO_ID]: userData?.vimeoUserId,
  });
};

export const logoutTracking = (vimeoId: string) => {
  const loginType = getLocalStorage('loginType') || 'email';
  pushTracking({
    event: Event.CUSTOMER_LOGOUT,
    [Params.CUSTOMER_LOGGED_IN_STATUS]: PARAM_VALUE.TRUE,
    [Params.VIMEO_ID]: vimeoId,
    [Params.LOGIN_TYPE]: loginType,
  });
};

export const contentViewTracking = (
  title: string,
  type: 'video' | 'article' | 'product' | 'other',
  topic: string,
  location: string
) => {
  pushTracking({
    event: Event.CONTENT_VIEW,
    [Params.CONTENT_TITLE]: title,
    [Params.CONTENT_TYPE]: type,
    [Params.CONTENT_TOPIC]: topic,
    [Params.CONTENT_LOCATION]: location,
  });
};

export const contentListViewTracking = (
  titles: string[],
  type: 'videos' | 'articles' | 'products' | 'others',
  topic: string,
  location: string
) => {
  pushTracking({
    event: Event.CONTENT_LIST_VIEW,
    [Params.CONTENT_TITLE]: titles,
    [Params.CONTENT_TYPE]: type,
    [Params.CONTENT_TOPIC]: topic,
    [Params.CONTENT_LOCATION]: location,
  });
};

export const contentClickTracking = ({
  contentIndex,
  contentListTitle,
  contentListIndex,
  contentTitle,
  contentType,
  contentTopic,
  contentLocation,
}: ContentClickParams) => {
  // contentListIndex is the position of the content in the list
  // contentIndex is the position of the slice in the page
  logEvent('content_click', {
    content_list_title: contentListTitle,
    content_name: contentTitle,
    content_type: contentType,
    content_index: contentListIndex,
  });
  pushTracking({
    event: Event.CONTENT_CLICK,
    [Params.CONTENT_INDEX]: contentIndex,
    [Params.CONTENT_LIST_TITLE]: contentListTitle,
    [Params.CONTENT_LIST_INDEX]: contentListIndex,
    [Params.CONTENT_TITLE]: contentTitle,
    [Params.CONTENT_TYPE]: contentType,
    [Params.CONTENT_TOPIC]: contentTopic,
    [Params.CONTENT_LOCATION]: contentLocation,
  });
};

export const linkClickTracking = ({
  eventName,
  link,
  contentListTitle = '',
}: {
  eventName: string;
  link: string;
  contentListTitle: string;
}) => {
  logEvent(eventName, {
    [Params.STEP_LABEL]: link,
    [Params.CONTENT_LIST_TITLE]: contentListTitle,
  });
};
