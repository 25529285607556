const COLORS = {
  white: '#ffffff',
  whiteOpacity005: 'rgba(255, 255, 255, 0.05)',
  whiteOpacity07: 'rgba(255, 255, 255, 0.7)',
  whiteOpacity09: 'rgba(255, 255, 255, 0.9)',
  darkBlack: '#000000',
  neoBlack: '#171f2e',
  black: '#111111',
  lightishGrey: '#dddddd',
  lightBlack: '#222222',
  blackOpacity: 'rgba(0,0,0,0.6)',
  darkGrey: '#333333',
  darkGrey2: '#323232',
  darkGreyOpacity01: 'rgba(51, 51, 51, 0.1)',
  darkGreyOpacity02: 'rgba(51, 51, 51, 0.25)',
  darkGreyOpacity05: 'rgba(51, 51, 51, 0.5)',
  greyOpacity06: 'rgba(34, 34, 34, 0.6)',
  lightGrey06: 'rgba(123, 123, 123, 0.6)',
  lightGrey04: 'rgba(123, 123, 123, 0.4)',
  doveGrey: '#6d6d6d',
  dimGrey: '#747474',
  silver: '#bfbfbf',
  gandalfTheGrey: '#dddddd',
  lightGreyB0: '#b0b0b0',
  lightGreyB1: '#b1b1b1',
  lightGreyD9: '#d9d9d9',
  lightGrey7B: '#7b7b7b',
  lightGreyAE: '#aeaeae',
  lightGrey5C: '#5c5c5c',
  lightGreyE5: '#e5e5e5',
  whiteSmoke: '#f5f5f5',
  yetAnotherGrey: '#d7d5d5',
  errorRed: '#F83726',
  errorText: '#F27D7A',
  darkRed: '#EF3340',
  greyLetter: '#4e4e4e',
  borderGrey: '#d7d5d5',
  voucherGrey: '#e7e7e7',
  successGreen: '#39c16c',
  green: '#048C04',
  accentGreen: '#12D45D',
  lightGreen: '#12d45d',
};

export default COLORS;
