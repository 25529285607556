export const DOLLAR_CURRENCIES = {
  SGD: 'SGD', // Singapore Dollar
  CAD: 'CAD', // Canadian Dollar
  AUD: 'AUD', // Australian Dollar
  NZD: 'NZD', // New Zealand Dollar
  AED: 'AED', // United Arab Emirates Dirham
  USD: 'USD', // United States Dollar
  MYR: 'RM', // Malaysian Ringgit
  HKD: 'HKD', // Hong Kong Dollar
  BRL: 'BRL', // Brazilian real
  ZAR: 'ZAR', // South African Rand
  CHF: 'CHF', // Swiss Franc
};

export const OTHER_CURRENCIES = {
  EUR: '€', // Euro
  GBP: '£', // Great British Pound
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese yen
};

export const MONTHLY_COUNTRIES = ['US', 'CA', 'NZ', 'IN', 'GB'];

export const currencyFormat = (currency, price) => {
  // having result in array to separate amount from currency and show in different sections
  let result = [`$${price}`, ''];
  if (DOLLAR_CURRENCIES[currency]) {
    result = [`$${price}`, DOLLAR_CURRENCIES[currency]];
  } else if (OTHER_CURRENCIES[currency]) {
    result = [`${OTHER_CURRENCIES[currency]}${price}`, ''];
  }
  return result;
};

export const priceFormat = (_cents, currency, toFixedNumber, interval = 0) => {
  let cents = _cents;
  // to count price per month
  if (interval) {
    cents = _cents / interval;
  }
  let displayPrice = currencyFormat(currency, cents / 100);
  if (currency === 'JPY') {
    if (cents.toString().length > 6) {
      cents = cents.toFixed(2);
    }
    displayPrice = currencyFormat(currency, cents);
  } else if (toFixedNumber) {
    displayPrice = currencyFormat(currency, (cents / 100).toFixed(toFixedNumber));
  }
  return displayPrice;
};

export const getBillingPeriod = (period) => {
  let result = 'monthly';
  if (period === 3) {
    result = 'quarterly';
  }
  if (period === 6) {
    result = 'biannually';
  }
  if (period === 12) {
    result = 'annually';
  }
  return result;
};
