/* eslint max-len: ["error", { "code": 120 }] */
import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/common/layout';

import { deleteLocalStorage, isBrowser } from '../utils/utilities';
import { ACTIVATION_CODE_FORMATTED } from '../constants/localStorageKeys';
import PartnerSuccess from '../components/partner/success/PartnerSuccess';

const PartnerSuccessActivatedTemplate = ({ data, location, pageContext }) => {
  const { prismicSignUpLayout, prismicPartnerSuccessPage } = data;
  const { lang } = pageContext;

  const handleStarted = () => {
    if (isBrowser) {
      deleteLocalStorage(ACTIVATION_CODE_FORMATTED);
      window.location.href = '/survey/start';
    }
  };

  return (
    <Layout
      data={prismicSignUpLayout.data}
      contentWidth={380}
      currentStep={3}
      omitClose
      lang={lang}
      pathName="/partner/signup/success"
      mobileBottomMargin={120}
      title="Partner Signup Success"
    >
      <PartnerSuccess
        data={prismicPartnerSuccessPage.data}
        prismicSignUpLayout={prismicSignUpLayout}
        pageContext={pageContext}
        location={location}
        handleStarted={handleStarted}
      />
    </Layout>
  );
};

export default withPrismicPreview(PartnerSuccessActivatedTemplate);

export const query = graphql`
  query ($lang: String!) {
    prismicPartnerSuccessPage(lang: { eq: $lang }) {
      _previewable
      data {
        description_message
        button_text
        success_title
        welcome_back_title
        contact_partner_message
        dont_refresh
        new_user_message
      }
    }
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }
  }
`;
